import { Fragment, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Container from 'components/atoms/Container';
import Logo from 'components/atoms/Logo';
import useNavigation from 'hooks/useNavigation';
import FooterNavigation from 'components/molecules/FooterNavigation';
import { MatchRequestItem } from 'components';
import ReactFlagsSelect from 'react-flags-select';
import useCustomAlert from 'hooks/useAlert';
import store from 'app/store';
import { setAlert } from 'features/alert/alertSlice';

function Footer() {
  const { t, i18n } = useTranslation('global');
  const lngCountry = localStorage.getItem('wagenGamesLanguajeCountry');
  const [selectedLanguaje, setSelectedLanguaje] = useState();
  const [selectedCountry, setSelectedCountry] = useState(lngCountry || 'US');
  const { navigationFooter } = useNavigation();
  const { matchRequests, errorsMatchRequest } = useSelector(
    (state) => state.user
  );

  const showAlert = useCustomAlert();

  const spanishLanguajeCountries = useRef(['ES']);
  const englishLanguajeCountries = useRef(['US']);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguaje);
  }, [i18n, selectedLanguaje]);

  useEffect(() => {
    updateLanguage(selectedCountry);
  }, [selectedCountry]);

  const updateLanguage = (code) => {
    localStorage.setItem('wagenGamesLanguajeCountry', code);

    setSelectedCountry(code);

    if (spanishLanguajeCountries.current.includes(code))
      setSelectedLanguaje('es');
    else if (englishLanguajeCountries.current.includes(code))
      setSelectedLanguaje('en');
    else setSelectedLanguaje('en');
  };

  useEffect(() => {
    showAlert();
  }, [showAlert]);

  useEffect(() => {
    if (errorsMatchRequest && errorsMatchRequest?.length > 0) {
      store.dispatch(
        setAlert({
          message: `messages.${errorsMatchRequest?.[0].errorCode}`,
          type: 'error',
        })
      );
    }
  }, [errorsMatchRequest]);

  useEffect(() => {
    if (errorsMatchRequest && errorsMatchRequest.length > 0) {
      store.dispatch(
        setAlert({
          message: `messages.${errorsMatchRequest[0].errorCode}`,
          type: 'error',
        })
      );
    }
  }, [errorsMatchRequest]);

  return (
    <Fragment>
      <div className="bg-body-100 w-full py-10 lg:pt-5 lg:pb-20">
        <Container>
          <div className="flex justify-end mb-10">
            <div className="max-w-md language">
              <ReactFlagsSelect
                countries={['ES', 'US']}
                selected={selectedCountry}
                onSelect={(code) => updateLanguage(code)}
                customLabels={{ ES: 'Spanish', US: 'English' }}
              />
            </div>
          </div>
        </Container>
        <Container className="lg:flex justify-between items-start">
          <div className="lg:w-1/4 mb-4 xl:mb-0">
            <Logo width="120px" />
          </div>
          <div className="grid grid-cols-2 lg:flex lg:justify-between lg:gap-12 lg:w-3/4">
            <FooterNavigation data={navigationFooter} type="page" />
            <FooterNavigation data={navigationFooter} type="extra" />
            <FooterNavigation data={navigationFooter} type="legal" />
            <FooterNavigation data={navigationFooter} type="rrss" />
          </div>
        </Container>
      </div>

      {/* Match request section */}
      {matchRequests.length !== 0 && (
        <div className="fixed right-2 bottom-2 md:bottom-8 md:right-8 w-[94%] md:max-w-[530px] yellowCorners mb-6 z-50">
          <div className="bg-brown-800 pt-10 pb-4 border-2 border-opacity-25 border-yellow-500">
            <div className="px-10">
              <p className="text-yellow-400 text-3xl">
                {t('pages.play-subscreen.match-requests.title')}
              </p>
            </div>
            {/* Games list */}
            <div className="mt-6 max-h-[280px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500">
              {matchRequests.length !== 0 ? (
                <div className="flex flex-col gap-3">
                  {matchRequests.map((element, index) => (
                    <MatchRequestItem key={index} match={element} />
                  ))}
                </div>
              ) : (
                <div className="">
                  <p className="text-gray-400 italic">
                    {t(
                      'pages.play-subscreen.match-requests.no-match-requests-message'
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Footer;
