import { useSelector } from 'react-redux';
import wagenCoin from 'assets/images/wagencoin.svg';
import CardBgVs from '../atoms/CardBgVs';
import Image from 'components/atoms/Image';
import { useTranslation } from 'react-i18next';

export default function PlayMatch() {
  const { t } = useTranslation('global');
  const { userData } = useSelector((state) => state.user);
  const ctaPlay = userData?.steam64Id
    ? `steam://rungame/730/${userData.steam64Id}/+connect ${userData?.currentMatch?.match?.serverIp}`
    : '';

  if (userData?.currentMatch?.currentlyPlaying === true) {
    return (
      <div
        data-testid="playMatch"
        className="bg__glass bg-black bg-opacity-80 flex justify-center items-center fixed w-screen h-screen right-0 top-0 z-50"
      >
        <div className="bg-body-200 max-w-[829px] w-11/12 rounded-lg flex flex-col z-10 py-8 px-8">
          <div className="flex flex-col flex-grow md:flex-row justify-between items-center w-full">
            <div className="flex flex-col justity-center items-center w-full max-w-[330px] relative">
              <article className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center flex-col w-3/4 mx-auto z-20">
                <div className="relative overflow-hidden border-4 border-yellow-500 rounded-full w-20 h-20 md:w-36 md:h-36 flex items-center justify-center mx-auto mt-3">
                  {userData?.currentMatch?.match?.oponent1?.avatar !==
                    undefined && (
                    <Image
                      src={userData?.currentMatch?.match?.oponent1?.avatar}
                      alt={`Avatar of ${userData?.currentMatch?.match?.oponent1?.name}`}
                      type="cover"
                    />
                  )}
                </div>
                <div className="mt-4 mb-0 py px-5 uppercase font-bold text-center">
                  <p className="text-gray-100 mb-0 text-xl break-words">
                    {userData?.currentMatch?.match?.oponent1?.name}
                  </p>
                </div>
                <div className="text-gray-300 block text-center">
                  <p className="flex justify-center gap-1">
                    <span className="block text-gray-300 font-light">
                      Unique Code:
                    </span>
                    <span className="font-bold">
                      {userData?.currentMatch?.match?.oponent1?.idPublic}
                    </span>
                  </p>
                  <div className="flex justify-center mt-4 gap-3">
                    <div className="clip-path-button bg-yellow-500 text-black py-1 px-3 font-bold">
                      <span>K/D: </span>
                      <span className="font-light">
                        {parseFloat(
                          userData?.currentMatch?.match?.oponent1?.kd
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="clip-path-button bg-yellow-500 text-black py-1 px-3 font-bold">
                      <span>Win: </span>
                      <span className="font-light">
                        {`${parseFloat(
                          userData?.currentMatch?.match?.oponent1?.win
                        ).toFixed(2)}%`}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <CardBgVs />
              <div className="absolute top-0 right-0 flex items-center justify-center w-28 h-12 py-4 px-4 z-30">
                <div className="flex gap-1 items-center">
                  <span className="text-white uppercase font-bold text-lg">
                    {userData?.currentMatch?.match?.oponent1?.waggies}
                  </span>
                  <Image src={wagenCoin} alt="Wagen Coin" width="25px" />
                </div>
              </div>
            </div>
            <div className="w-[100px] flex justify-center items-center z-20">
              <div className="my-2 md:my-7 w-12 h-12 md:w-24 md:h-24 flex items-center justify-center">
                <p className="versus">VS</p>
              </div>
            </div>
            <div className="flex flex-col justity-center items-center w-full max-w-[330px] relative">
              <article className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center flex-col w-3/4 mx-auto z-20">
                <div className="relative overflow-hidden border-4 border-yellow-500 rounded-full w-20 h-20 md:w-36 md:h-36 flex items-center justify-center mx-auto mt-3">
                  <Image
                    src={userData?.currentMatch?.match?.oponent2?.avatar}
                    alt={`Avatar of ${userData?.currentMatch?.match?.oponent2?.name}`}
                    type="cover"
                  />
                </div>
                <div className="mt-4 mb-0 py px-5 uppercase font-bold text-center">
                  <p className="text-gray-100 mb-0 text-xl break-words">
                    {userData?.currentMatch?.match?.oponent2?.name}
                  </p>
                </div>
                <div className="text-gray-300 block text-center">
                  <p className="flex justify-center gap-1">
                    <span className="block text-gray-300 font-light">
                      Unique Code:
                    </span>
                    <span className="font-bold">
                      {userData?.currentMatch?.match?.oponent2?.idPublic}
                    </span>
                  </p>
                  <div className="flex justify-center mt-4 gap-3">
                    <div className="clip-path-button bg-yellow-500 text-black py-1 px-3 font-bold">
                      <span>K/D: </span>
                      <span className="font-light">
                        {parseFloat(
                          userData?.currentMatch?.match?.oponent2?.kd
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="clip-path-button bg-yellow-500 text-black py-1 px-3 font-bold">
                      <span>Win: </span>
                      <span className="font-light">
                        {`${parseFloat(
                          userData?.currentMatch?.match?.oponent2?.win
                        ).toFixed(2)}%`}
                      </span>
                    </div>
                  </div>
                </div>
              </article>
              <CardBgVs />
              <div className="absolute top-0 right-0 flex items-center justify-center w-28 h-12 py-4 px-4 z-30">
                <div className="flex gap-1 items-center">
                  <span className="text-white uppercase font-bold text-lg">
                    {userData?.currentMatch?.match?.oponent2?.waggies}
                  </span>
                  <Image src={wagenCoin} alt="Wagen Coin" width="25px" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="text-white text-center font-light mt-6 mb-2">
              {t('components.play-match.message')}
            </p>
            <div className="w-full mx-auto text-base clip-path-button font-[900] uppercase">
              <div className="flex items-center justify-center gap-2 mb-3">
                <p className="text-gray-200 clip-path-button bg-black py-2 pl-6 pr-10">
                  {userData?.currentMatch?.match?.serverIp}
                </p>
              </div>
              <div className="flex items-center justify-center">
                <a
                  href={ctaPlay}
                  rel="noreferrer"
                  className="text-base clip-path-button font-[900] uppercase | lg:text-xl ease-linear overflow-hidden bg-yellow-500 w-40 flex justify-center"
                >
                  <span className="flex gap-1 items-center py-3 px-6">
                    <span className="text-sm">Connect</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
