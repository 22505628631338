import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();

  // eslint-disable-next-line
  setTimeout(() => {
    navigate('/');
  }, 2000);

  return (
    <div className=" h-screen w-screen flex flex-col justify-center items-center bg-brown-700">
      <p className="text-yellow-600 text-9xl font-quantum my-4">404</p>
      <p className="text-yellow-600 text-3xl font-quantum my-4">
        {t('pages.404.message')}
      </p>
    </div>
  );
};

export default PageNotFound;
