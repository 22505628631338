import { useEffect, memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGa from 'react-ga4';
import Protected from 'components/atoms/ProtectedRoute';
import Layout from 'components/templates/Layout';
import Landing from 'components/pages/Landing';
import LoginCallback from './pages/LoginCallback/index';
import Store from 'components/pages/Store';
import Contact from 'components/pages/Contact';
import BuyWaggies from 'components/pages/BuyWaggies';
import PaymentSuccess from './pages/StripePayment/PaymentSuccess';
import PaymentCancel from 'components/pages/PaymentCancel';
import PageNotFound from './pages/PageNotFound/index';
import WeaponDetail from 'components/pages/WeaponDetail';
import PlayMatch from 'components/organisms/PlayMatch';
import FinishedMatch from 'components/organisms/FinishedMatch';
import TermsAndConditions from 'components/pages/TermsAndConditions';
import CookiesPolicy from 'components/pages/CookiesPolicy';
import Privacy from 'components/pages/Privacy';
import Register from 'components/pages/Register';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }
  }, []);

  return (
    <>
      <PlayMatch />
      <FinishedMatch />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="/" element={<Landing />} />
          <Route
            exact
            path="/login/steam/callback"
            element={<LoginCallback />}
          />
          <Route path="/user" element={<Protected subscreen={'home'} />} />
          <Route path="/user/home" element={<Protected subscreen={'home'} />} />
          <Route
            path="/user/profile"
            element={<Protected subscreen={'profile'} />}
          />
          <Route path="/user/play" element={<Protected subscreen={'play'} />} />
          <Route
            path="/user/friends"
            element={<Protected subscreen={'friends'} />}
          />
          <Route path="/store" element={<Store />} />
          <Route path="/store/:weaponid" element={<WeaponDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/privacy-policy" element={<Privacy />} />

          <Route exact path="/buyWaggies" element={<BuyWaggies />} />
          <Route exact path="/payment/sucess" element={<PaymentSuccess />} />
          <Route exact path="/payment/cancel" element={<PaymentCancel />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default memo(App);
