import { io } from 'socket.io-client';

import {
  setUser,
  updateTradelink,
  setSocketConnected,
  removeFriend,
  addFriend,
  removeFriendshipRequest,
  friendConnected,
  friendDisconnected,
  addMatchRequest,
  addMatchRequestError,
  removeMatchRequestError,
  removeMatchRequest,
  addFriendshipRequest,
  startGame,
  endGame,
  setIsUserLoggedIn,
} from 'features/user/userSlice';
import { clearAlert, setAlert } from 'features/alert/alertSlice';

import store from 'app/store';

let socket;
let state = store.getState();
const fecha = new Date();

const opcionesFecha = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

const opcionesHora = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};

const formatoFecha = new Intl.DateTimeFormat('es-ES', opcionesFecha).format(
  fecha
);
const formatoHora = new Intl.DateTimeFormat('es-ES', opcionesHora).format(
  fecha
);

const fechaHora = `${formatoFecha} ${formatoHora}`;
/* setTimeout(() => {
  endGameEvent({
    action: 'endGame',
    data: {
      betQuantity: 12,
      senderWins: false,
      matchDuration: 34,
      senderData: {
        idPublic: '123456789',
        userName: 'Oponente 1 SenderData',
        userAvatar:
          'https://render.fineartamerica.com/images/rendered/default/print/images/artworkimages/medium/3/midjourney-lightning-cat.jpg',
        KD: 10,
        win: 0,
      },
      receiverData: {
        idPublic: '987654321',
        userName: 'Oponente 2 ReceiverData',
        userAvatar:
          'https://render.fineartamerica.com/images/rendered/default/print/images/artworkimages/medium/3/waterfall-midjourney.jpg',
        KD: 1,
        win: 0,
      },
    },
    error: null,
  });
}, 10000); */

// setTimeout(() => {
//   userDataSocketMessageEvent({
//     action: 'userData',
//     data: {
//       idPublic: 'ozgkeb',
//       tradelink: '',
//       waggies: 99888,
//       name: 'gonzalo.eizaguirre',
//       avatar:
//         'https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
//       kd: 5,
//       win: 0,
//       friends: [
//         {
//           idPublic: 'ytv5wa',
//           online: false,
//           userAvatar:
//             'https://avatars.steamstatic.com/35096d19fe828c3792b5752d10e6003c11e0ffee_full.jpg',
//           userName: 'El monje pelotudo',
//         },
//         {
//           idPublic: '6n9utt',
//           online: true,
//           userAvatar:
//             'https://avatars.steamstatic.com/054c55794fa3cda3d32ffda9751b4953f61059b7_full.jpg',
//           userName: 'eizaguirregonzalo',
//         },
//       ],
//       friendshipRequests: [],
//       lastGames: [
//         {
//           matchPublicId: 'g6265s',
//           betAmount: 2,
//           duration: 3,
//           friend: {
//             idPublic: 'ytv5wa',
//             online: false,
//             userAvatar:
//               'https://avatars.steamstatic.com/35096d19fe828c3792b5752d10e6003c11e0ffee_full.jpg',
//             userName: 'El monje pelotudo',
//           },
//           friendKills: 0,
//           userKills: 5,
//           userWins: true,
//         },
//       ],
//       matchSentRequests: [],
//       matchReceivedRequests: [],
//       nowPlaying: {
//         matchPublicId: 'fqf0zs',
//         serverPublicIp: '13.38.83.217',
//         betQuantity: 12,
//         friendData: {
//           idPublic: '6n9utt',
//           steam64Id: '76561198998825377',
//           kd: 0,
//           win: 0,
//           userAvatar:
//             'https://avatars.steamstatic.com/054c55794fa3cda3d32ffda9751b4953f61059b7_full.jpg',
//           userName: 'eizaguirregonzalo',
//         },
//       },
//     },
//   });
// }, 1000);

const updateState = () => {
  state = store.getState();
  store.dispatch(clearAlert());
};

// ---------------- SOCKET EVENTS ----------------
// All these functions are executed when a new message arrives with
// a socket.on("message")

/**
 * Message event updateTradelink
 * @param {*} arg message value
 */
const userDataSocketMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({
        message: `messages.${arg.error.errorCode}`,
        type: 'error',
      })
    );
    store.dispatch(setIsUserLoggedIn(false));
    return;
  }

  store.dispatch(setUser(arg.data));

  for (const match of arg.data.matchSentRequests) {
    const matchReceivedRequest = {
      matchReceiverUser: {
        avatar: match.friend.userAvatar,
        name: match.friend.userName,
        idPublic: match.friend.idPublic,
      },
      matchData: {
        ammountBet: match.betAmount,
      },
      matchId: match.matchId,
    };
    store.dispatch(addMatchRequest(matchReceivedRequest));
  }

  for (const match of arg.data.matchReceivedRequests) {
    const matchSentRequest = {
      matchSenderUser: {
        avatar: match.friend.userAvatar,
        name: match.friend.userName,
        idPublic: match.friend.idPublic,
      },
      matchData: {
        ammountBet: match.betAmount,
      },
      matchId: match.matchId,
    };
    store.dispatch(addMatchRequest(matchSentRequest));
  }

  if (
    arg.data.nowPlaying !== null &&
    Object.entries(arg.data.nowPlaying).length > 0
  ) {
    store.dispatch(startGame(arg.data.nowPlaying));
  }
};

/**
 * Message event updateTradelink
 * @param {*} arg message value
 */
const updateTradelinkMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  if (arg.updated) {
    store.dispatch(updateTradelink(arg.newTradelink));
  }
};

/**
 * Message event makeFriendRequest
 * @param {*} arg message value
 */
const makeFriendRequestMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  if (arg.requestSent) {
    // add friend request and show alert to the user receiving the request
    if (arg.receiverPublicId === state.user.userData.idPublic) {
      store.dispatch(addFriendshipRequest(arg.data));
      store.dispatch(
        setAlert({
          message: 'alert.friendship-request-received',
          type: 'success',
        })
      );
    } else {
      // show alert to the user that has sent the request
      // alert.show("Friendship request sent");
      store.dispatch(
        setAlert({ message: 'alert.friendship-request-sent', type: 'success' })
      );
    }
  }
};

/**
 * Message event deleteFriendship
 * @param {*} arg message value
 */
const deleteFriendshipMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  if (arg.deleted) {
    store.dispatch(removeFriend(arg.friendPublicId));
    if (arg.notifyUser) {
      /* alert.show("User deleted"); */
    }
  } else {
    if (arg.notifyUser) {
      /* alert.error("Cant delete user"); */
    }
  }
};

/**
 * Message event acceptFriendRequest
 * @param {*} arg message value
 */
const acceptFriendRequestMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  if (arg.accepted) {
    store.dispatch(removeFriendshipRequest(arg.data.friendshipRequestId));
    store.dispatch(addFriend(arg.data.user));
  }
};

/**
 * Message event rejectFriendRequest
 * @param {*} arg message value
 */
const rejectFriendRequestMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  if (arg.rejected) {
    store.dispatch(
      removeFriendshipRequest(arg.friendshipRequestData.friendshipRequestId)
    );
    store.dispatch(
      setAlert({
        message: 'alert.friendship-request-deleted',
        type: 'info',
      })
    );
  }
};

/**
 * Message event newMatchRequest
 * @param {*} arg message value
 */
const newMatchRequestMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(addMatchRequestError(arg.error));
    setTimeout(() => {
      store.dispatch(removeMatchRequestError(arg.error));
      store.dispatch(clearAlert());
    }, 5000);
  } else {
    store.dispatch(addMatchRequest(arg.data));
  }
};

/**
 * Message event cancelMatchRequest
 * @param {*} arg message value
 */

const cancelMatchRequestMessageEvent = (arg) => {
  setTimeout(() => {
    store.dispatch(removeMatchRequest(arg.data));
  }, 500000);
};

/**
 * Message event acceptMatchRequest
 * @param {*} arg
 */
const acceptMatchRequestEvent = (arg) => {
  if (arg !== null) {
    store.dispatch(removeMatchRequest(arg.data));
    if (!arg.error) {
      store.dispatch(startGame(arg.data));
    } else {
      store.dispatch(addMatchRequestError(arg.error));
      setTimeout(
        () => store.dispatch(removeMatchRequestError(arg.error)),
        5000
      );
    }
    if (arg.error) {
      const newArg = {
        action: 'rejectMatchRequest',
        data: {
          matchPublicId: arg.data.matchPublicId,
          rejected: true,
        },
        error: null,
      };
      cancelMatchRequestMessageEvent(newArg);

      store.dispatch(
        setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
      );
      setTimeout(() => {
        store.dispatch(clearAlert());
      }, 5000);
    }
  }
};

const endGameEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  store.dispatch(endGame(arg.data));
};

/**
 * Message event friendConnected
 * @param {*} arg message value
 */
const friendConnectedMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  store.dispatch(friendConnected(arg.data));
};

/**
 * Message event friendDisconnected
 * @param {*} arg message value
 */
const friendDisconnectedMessageEvent = (arg) => {
  if (arg.error) {
    store.dispatch(
      setAlert({ message: `messages.${arg.error.errorCode}`, type: 'error' })
    );
    setTimeout(() => {
      store.dispatch(clearAlert());
    }, 5000);

    return;
  }

  store.dispatch(friendDisconnected(arg.data));
};

/**
 * @description Connects the socket to the server and listens for messages from the server to update the state of the app
 */
const connectSocket = () => {
  updateState();

  if (state.user.isUserLoggedIn) {
    socket = io(process.env.REACT_APP_HOST, {
      path: process.env.REACT_APP_BACKEND_SOCKET_PATH,
      reconnection: true, // Ensure reconnection is enabled
      reconnectionAttempts: Infinity, // Infinite reconnection attempts
      reconnectionDelay: 1000, // 1 second delay between reconnection attempts
      reconnectionDelayMax: 5000, // Maximum delay of 5 seconds
    });

    socket.on('connect', () => {
      updateState();
      store.dispatch(setSocketConnected(true));
      console.log(
        '%c Connected to socket',
        'background: green; color:white; font-weight: bold',
        fechaHora
      );
      sendMessage({
        action: 'userData',
      });
    });

    socket.on('reconnect', () => {
      updateState();

      console.log(
        '%c Reconnect to socket',
        'background: orange; color:black; font-weight: bold',
        fechaHora
      );
    });

    socket.on('connect_error', () => {
      updateState();
      console.log(
        '%c Error connecting to socket',
        'background: red; color:white; font-weight: bold',
        fechaHora
      );
      startDisconnectTimer();
    });

    socket.on('disconnect', () => {
      updateState();
      console.log(
        '%c Disconnected from socket',
        'background: red; color:white; font-weight: bold',
        fechaHora
      );
    });

    socket.on('message', (arg) => {
      // see what action is received from the backend server
      console.log('Mensaje recibido del back: ' + JSON.stringify(arg));

      updateState();

      switch (arg.action) {
        // get the user data    -- ok
        case 'userData':
          userDataSocketMessageEvent(arg);
          break;

        // tradelink updated    -- ok
        case 'updateTradelink':
          updateTradelinkMessageEvent(arg);
          break;

        // new friendship request made  -- ok
        case 'makeFriendshipRequest':
          makeFriendRequestMessageEvent(arg);
          break;

        // deteles a frienship with other user  -- ok
        case 'deleteFriendship':
          deleteFriendshipMessageEvent(arg);
          break;

        // accept friendship request    -- ok
        case 'acceptFriendshipRequest':
          acceptFriendRequestMessageEvent(arg);
          break;

        // reject the frienship request -- ok
        case 'rejectFriendshipRequest':
          rejectFriendRequestMessageEvent(arg);
          break;

        // new match request received
        case 'newMatchRequest':
          newMatchRequestMessageEvent(arg);
          break;

        // cancell a match request
        case 'rejectMatchRequest':
          cancelMatchRequestMessageEvent(arg);
          break;

        // the other user has accepted the match
        case 'acceptMatchRequest':
          acceptMatchRequestEvent(arg);
          break;

        // the game has finished
        case 'endGame':
          endGameEvent(arg);
          break;

        // a friend has connected to the platform. We update the friend status
        case 'friendConnected':
          friendConnectedMessageEvent(arg);
          break;

        // a friend has disconnected from the platform. We update the friend status
        case 'friendDisconnected':
          friendDisconnectedMessageEvent(arg);
          break;

        // default action: do nothing
        default:
          break;
      }
    });
  }
};

/**
 * @description Disconnects the socket connection
 */
const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

const startDisconnectTimer = () => {
  if (socket) {
    store.dispatch(setSocketConnected(false));
  }
};

/**
 * Sends a message through the socket connection if it is stablished
 * @param {*} message
 */
const sendMessage = (message) => {
  socket?.emit('message', message);
  console.log('Emitting message: ' + JSON.stringify(message));
};

/**
 * Update the tradelink
 * @param {*} newTradelink
 */
const updateTradelinkSocket = (newTradelink) => {
  const message = {
    action: 'updateTradelink',
    data: {
      newTradelink,
    },
  };
  sendMessage(message);
};

/**
 * Make a new friendship request
 * @param {*} newFriendIF
 */
const makeNewFriendshipRequest = (newFriendId) => {
  const message = {
    action: 'makeFriendshipRequest',
    data: {
      friendPublicId: newFriendId,
    },
  };
  sendMessage(message);
};

/**
 * Delete friendship request
 * @param {*} friendId
 */
const removeFrienship = (friendshipId) => {
  const message = {
    action: 'deleteFriendship',
    data: {
      friendPublicId: friendshipId,
    },
  };
  sendMessage(message);
};

/**
 * Accepts a frienship request
 * @param {*} friendshipId
 */
const acceptFriendRequest = (friendshipRequestId) => {
  const message = {
    action: 'acceptFriendshipRequest',
    data: {
      friendshipRequestId,
    },
  };
  sendMessage(message);
};

/**
 * Remove a frienship request
 * @param {*} frienshipId
 */
const rejectFriendRequest = (frienshipId) => {
  const message = {
    action: 'rejectFriendshipRequest',
    data: {
      friendshipRequestId: frienshipId,
    },
  };
  sendMessage(message);
};

/**
 * Creates a new match request with the data passed
 * @param {*} matchData
 */
const createMatchRequest = (matchData) => {
  sendMessage(matchData);
};

/**
 * Send by socket that the match has been cancelled or rejected
 * @param {*} matchPublicId
 */
const deleteMatchRequest = (matchPublicId) => {
  const message = {
    action: 'rejectMatchRequest',
    data: {
      matchPublicId,
    },
  };

  sendMessage(message);
};

/**
 * Send by socket that the match has been accepted
 * @param {*} matchPublicId
 */
const acceptMatchRequest = (matchPublicId) => {
  const message = {
    action: 'acceptMatchRequest',
    data: {
      matchPublicId,
    },
  };

  sendMessage(message);
};

export {
  connectSocket,
  disconnectSocket,
  sendMessage,
  updateTradelinkSocket,
  makeNewFriendshipRequest,
  removeFrienship,
  acceptFriendRequest,
  rejectFriendRequest,
  createMatchRequest,
  deleteMatchRequest,
  acceptMatchRequest,
};
