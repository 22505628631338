export default function Image(props) {
  if (props?.type === 'cover') {
    return (
      <img
        {...props}
        className="absolute top-1/2 left-1/2 w-[101%] h-auto max-h-none max-w-none min-h-full min-w-[101%] -translate-x-2/4 -translate-y-2/4 object-cover"
        alt={props.alt}
      />
    );
  }
  return <img {...props} className={`max-w-full ${props?.className}`} alt="" />;
}
