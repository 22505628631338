import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { createMatchRequest } from 'app/socketConnection';
import Image from 'components/atoms/Image';
import Select from 'react-select';
import useHome from 'hooks/useHome';
import Container from 'components/atoms/Container';
import Card from 'components/molecules/Card';
import Button from 'components/atoms/Button';
import PlaySubscreen from 'pages/PlaySubscreen';
import FriendList from 'components/organisms/FriendList';
import LastGamesList from 'components/organisms/LastGamesList';
import CurrentUser from 'components/molecules/CurrentUser';
import imageCompete from 'assets/images/bgCardCompete.jpg';
import FeaturedVideos from 'components/organisms/landing/FeaturedVideos';
import useSection from 'hooks/useSection';
import { clearSelectedFriend } from 'features/friend/selectedFriendSlice';
import { customStyles } from 'shared/utils';

export default function Games() {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const { featuredVideos } = useSection();

  const selectedFriend = useSelector((state) => state.selectedFriend.friend);

  const headers = [
    {
      id: 0,
      cta: {
        id: 0.1,
        name: `${t('pages.ctas.compete-now')}`,
        type: 'primary',
        event: () => createMatchRequest(),
      },
      image: {
        src: imageCompete,
        alt: 'Compete Now',
      },
    },
  ];

  const { promotions } = useHome();
  const { userData } = useSelector((state) => state.user);
  const [gameType, setGameType] = useState(0);
  const [usersAvailableToPlay, setUsersAvailableToPlay] = useState([]);
  const [selectedUserToPlayWith, setSelectedUserToPlayWith] = useState(null);
  const [waggiesToBet, setWaggiesToBet] = useState('');
  const alert = useAlert();

  useEffect(() => {
    if (userData && userData.friends) {
      let auxArray = [];
      for (const user of userData.friends) {
        if (user.online)
          auxArray = [
            ...auxArray,
            {
              value: user.idPublic,
              label: user.userName,
              online: true,
            },
          ];
      }
      setUsersAvailableToPlay(auxArray);
    }

    return () => {
      dispatch(clearSelectedFriend());
    };
  }, [userData]);

  useEffect(() => {
    if (userData && userData.friends) {
      const onlineFriends = userData.friends
        .filter((friend) => friend.online)
        .map((friend) => ({
          value: friend.idPublic,
          label: friend.userName,
          online: true,
        }));
      setUsersAvailableToPlay(onlineFriends);
    }
  }, [userData]);

  useEffect(() => {
    if (selectedFriend) {
      const preselectedOption = usersAvailableToPlay.find(
        (user) => user.label === selectedFriend.userName
      );
      setSelectedUserToPlayWith(preselectedOption || null);
    }
  }, [selectedFriend, usersAvailableToPlay]);

  const handleChange = (selectedOption) => {
    setSelectedUserToPlayWith(selectedOption);
    dispatch(clearSelectedFriend());
  };

  const createMatch = () => {
    dispatch(clearSelectedFriend());

    const matchData = {};
    matchData.action = 'newMatchRequest';
    matchData.data = {};
    let amountToBet;

    if (gameType === 0) {
      matchData.data.gameType = 'customized';

      if (
        selectedUserToPlayWith === null ||
        selectedUserToPlayWith === undefined
      ) {
        alert.error('Select a user to play with');
        return;
      }

      matchData.data.secondPlayer = {};
      matchData.data.secondPlayer.publicId = selectedUserToPlayWith.value;

      amountToBet = parseInt(waggiesToBet);
      if (!isNaN(amountToBet)) {
        matchData.data.amountOfWaggies = amountToBet;
      } else {
        alert.error('Error with the waggies ammount');
        return;
      }
    } else if (gameType === 1) {
      matchData.data.gameType = 'random';

      amountToBet = parseInt(waggiesToBet);
      if (!isNaN(amountToBet)) {
        matchData.data.amountOfWaggies = amountToBet;
      } else {
        alert.error('Error with the waggies ammount');
        return;
      }
    } else {
      return;
    }

    createMatchRequest(matchData);
  };

  /**
   * Helper for updating the value of waggiesToBet
   * @param {*} event
   */
  const updateWaggiesValue = (event) => {
    setWaggiesToBet(event.target.value);
  };

  const isNotEmpty = selectedUserToPlayWith && waggiesToBet.trim() !== '';

  return (
    <div className="w-full bg-body-500 py-12 md:py-16">
      <Container>
        <section className="mb-12 flex flex-col md:flex-row gap-6 md:justify-between md:gap-12">
          <div className="w-full lg:max-w-[294px] lg:shrink-0">
            <CurrentUser />
          </div>
          <div className="md:flex-grow md:flex md:items-center">
            <div id="competition" className="w-full">
              <section className="flex flex-col gap-8 pb-12 w-full | xl:flex-row xl:gap-12 xl:pb-0">
                <div
                  className={`
                    text-white rounded-[10px] bg-body-200 py-7 px-8 relative w-full overflow-hidden | lg:px-10 order-1 ${
                      isNotEmpty ? '' : 'disabled-card'
                    }
                  `}
                >
                  <div className="h-20 flex items-center justify-start | lg:h-36 | xl:h-56">
                    {isNotEmpty ? (
                      <Button
                        id="buttonPlay"
                        type="primary"
                        onClick={createMatch}
                        className="relative isNotEmpty z-10"
                      >
                        <span>{t('pages.ctas.play-now')}</span>
                      </Button>
                    ) : (
                      <div className="text-base inline-block clip-path-button px-5 py-3 font-[900] uppercase | lg:text-xl lg:px-7 lg:py-4 ease-linear overflow-hidden bg-yellow-500 text-black relative z-10 opacity-50">
                        <span>{t('pages.ctas.play-now')}</span>
                      </div>
                    )}
                    <Image
                      type="cover"
                      src={headers[0].image.src}
                      alt={headers[0].image.alt}
                    />
                  </div>
                </div>
                <div className="text-white rounded-[10px] py-7 sm:px-8 relative w-full overflow-hidden flex items-center | lg:px-0">
                  <div className="grid grid-cols-1 md:flex flex-col gap-6 w-full md:w-64">
                    <button
                      id="buttonPersonalized"
                      className="bg-yellow-500 text-black text-base inline-block clip-path-button px-5 py-2 font-[900] uppercase | lg:text-xl lg:px-7 lg:py-3 ease-linear overflow-hidden lg:w-full"
                      onClick={() => setGameType(0)}
                    >
                      {t(
                        'pages.play-subscreen.top-section.buttons.personalized.text'
                      )}
                    </button>
                    {gameType === 1 && (
                      <div id="buttonRandom">
                        <Button
                          className="relative z-10 lg:text-2xl lg:py-6 border-2 border-yellow-500"
                          type="secondary"
                          onClick={() => setGameType(0)}
                        >
                          {t(
                            'pages.play-subscreen.top-section.buttons.random.text'
                          )}
                        </Button>
                      </div>
                    )}
                    <Select
                      id="selectFriend"
                      options={usersAvailableToPlay}
                      styles={customStyles}
                      className="select-custom relative z-20"
                      isSearchable
                      placeholder={t(
                        'pages.play-subscreen.top-section.game.play-with.selection.text'
                      )}
                      isClearable
                      onChange={handleChange}
                      value={selectedUserToPlayWith}
                      defaultValue={selectedUserToPlayWith}
                    />
                    <div id="inputBet" className="flex z-10 h-12">
                      <label
                        id="wagiesToBet"
                        className="text-[13px] inline-flex items-center clip-path-button px-4 py-3 font-[900] uppercase shrink-0 ease-linear leading-1 bg-yellow-500 text-black z-10 md:w-24 xl:w-28 2xl:w-32 leading-3"
                      >
                        {t(
                          'pages.play-subscreen.top-section.game.bet-wagies.title'
                        )}
                      </label>
                      <input
                        name="wagiesToBet"
                        placeholder="200"
                        className="clip-path-button px-4 bg-black text-yellow-500 w-full border border-yellow-500 outline-none appearance-none pl-12 -ml-8"
                        value={waggiesToBet}
                        onChange={updateWaggiesValue}
                        type="text"
                        step="1"
                        pattern="\d+"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <section className="flex flex-col gap-8 mb-12 | xl:grid xl:grid-cols-2 xl:gap-24">
          <FriendList />
          <LastGamesList />
        </section>

        <section className="w-full pb-20 lg:grid lg:grid-cols-2 lg:gap-16">
          {promotions?.map((promotion) => (
            <Card variant="medium" key={promotion.id} data={promotion} />
          ))}
        </section>
      </Container>

      <FeaturedVideos data={featuredVideos} />

      <PlaySubscreen />
    </div>
  );
}
