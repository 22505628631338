import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import imagePlayToWinItem1 from 'assets/images/play_to_win_item_1.png';
import imagePlayToWinItem2 from 'assets/images/play_to_win_item_2.png';
import imagePlayToWinItem3 from 'assets/images/play_to_win_item_3.png';
import imagePlayToWin1 from 'assets/images/play_to_win_1.png';
import imagePlayToWin2 from 'assets/images/play_to_win_2.png';
import imageTitle from 'assets/images/waggie_coin.png';
import bgSignupToGetSkin from 'assets/images/bgSignupToGetSkin.jpg';
import useWeapon from 'hooks/useWeapon';

export default function useSection() {
  const { t } = useTranslation('global');
  const { weapons } = useWeapon();
  const { featureVideosList: featuredVideos } = useSelector(
    (state) => state.featuredVideos
  );

  const playToWin = {
    title: {
      first: `${t('pages.landing.play-to-win.title.first')}`,
      second: `${t('pages.landing.play-to-win.title.second')}`,
      third: `${t('pages.landing.play-to-win.title.third')}`,
    },
    titleImage: {
      src: imageTitle,
    },
    imageLeft: {
      src: imagePlayToWin1,
    },
    imageRight: {
      src: imagePlayToWin2,
    },
    items: [
      {
        id: `${t('pages.landing.play-to-win.colums.first.title.first')}`,
        title: `${t('pages.landing.play-to-win.colums.first.title.second')}`,
        description: `${t('pages.landing.play-to-win.colums.first.content')}`,
        image: {
          src: imagePlayToWinItem1,
          alt: `${t('pages.landing.play-to-win.colums.first.title.second')}`,
        },
      },
      {
        id: `${t('pages.landing.play-to-win.colums.second.title.first')}`,
        title: `${t('pages.landing.play-to-win.colums.second.title.second')}`,
        description: `${t('pages.landing.play-to-win.colums.second.content')}`,
        image: {
          src: imagePlayToWinItem2,
          alt: `${t('pages.landing.play-to-win.colums.second.title.second')}`,
        },
      },
      {
        id: `${t('pages.landing.play-to-win.colums.third.title.first')}`,
        title: `${t('pages.landing.play-to-win.colums.third.title.second')}`,
        description: `${t('pages.landing.play-to-win.colums.third.content')}`,
        image: {
          src: imagePlayToWinItem3,
          alt: `${t('pages.landing.play-to-win.colums.third.title.second')}`,
        },
      },
    ],
  };

  const featuredWeapons = {
    id: 1,
    title: `${t('pages.titles.featured-weapons')}`,
    cta: {
      name: `${t('pages.ctas.see-all-weapons')}`,
      link: '/store',
    },
    nodes: weapons || [],
  };

  const signupToGetSkin = {
    id: 2,
    title: `${t('pages.titles.choose-your-skins')}`,
    image: {
      src: bgSignupToGetSkin,
      alt: '',
    },
    cta: {
      id: 0.1,
      name: `${t('pages.ctas.sign-up')}`,
      variant: 'link',
      type: 'primary',
      url: process.env.REACT_APP_STEAM_LOGIN_URL,
    },
  };

  return {
    playToWin,
    featuredWeapons,
    featuredVideos,
    signupToGetSkin,
  };
}
