import Image from 'components/atoms/Image';

export default function FeatureVideoItem(props) {
  const { videoMiniature, videoTitle, videoUrl } = props;

  return (
    <article className="relative w-full h-full overflow-hidden">
      <h3 className="text-white absolute z-30 bottom-2 left-0 px-4 font-extrabold">
        {videoTitle}
      </h3>
      <Image type="cover" src={videoMiniature} alt={videoTitle} />
      <a
        href={videoUrl}
        target="_blank"
        rel="noreferrer noopener"
        className="absolute top-0 right-0 w-full h-full z-40 opacity-0"
      >
        {videoTitle}
      </a>
    </article>
  );
}
