import { useTranslation } from 'react-i18next';
import FeatureVideoItem from 'components/molecules/FeatureVideoItem';
import Container from 'components/atoms/Container';

export default function FeaturedVideos(props) {
  const { t } = useTranslation('global');
  const { data } = props;

  return (
    <Container className="py-16">
      <div className="text-center mb-16 | xl:flex xl:justify-center xl:items-center xl:gap-8">
        <h3 className="text-white uppercase font-black my-4 text-3xl | xl:my-0 xl:text-6xl">
          {t('components.featured-videos.featured-videos.main-title')}
        </h3>
      </div>
      <div className="featured__videos">
        {data?.map((video, index) => (
          <FeatureVideoItem
            key={index}
            videoTitle={video.videoTitle}
            videoUrl={video.videoUrl}
            videoInfluencer={video.videoInfluencer}
            videoMiniature={video.videoMiniature}
          />
        ))}
      </div>
    </Container>
  );
}
