import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/atoms/Button';

export default function FormContact() {
  const { t } = useTranslation('global');
  const initialFormData = {
    name: '',
    email: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/api/contact/newMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsSubmitted(true);
          setFormData(initialFormData);
          setTimeout(() => {
            setIsSubmitted(false);
          }, 4000);
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <div className="w-full mt-8">
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label
            className="block uppercase text-white text-sm font-bold mb-2"
            htmlFor="name"
          >
            {t('pages.inputs.your-name')}
          </label>
          <input
            className="appearance-none w-full clip-path-input bg-[#323131] py-2 px-6 text-white leading-tight h-[50px] focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder={t('pages.inputs.your-name')}
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label
            className="block uppercase text-white text-sm font-bold mb-2"
            htmlFor="email"
          >
            {t('pages.inputs.your-email')}
          </label>
          <input
            className="appearance-none w-full clip-path-input bg-[#323131] py-2 px-6 text-white leading-tight h-[50px] focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder={t('pages.inputs.your-email')}
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label
            className="block uppercase text-white text-sm font-bold mb-2"
            htmlFor="message"
          >
            {t('pages.inputs.your-message')}
          </label>
          <textarea
            className="appearance-none w-full clip-path-textarea bg-[#323131] py-4 px-6 text-white leading-tight h-[120px] focus:outline-none focus:shadow-outline"
            id="message"
            placeholder={t('pages.inputs.your-message')}
            rows="4"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <Button type="primary" variant="primary" onClick={handleSubmit}>
            {t('pages.ctas.submit')}
          </Button>
        </div>
      </form>
      {isSubmitted && (
        <div
          className="mt-8 bg-teal-100 rounded text-teal-900 px-4 py-3 shadow-md"
          role="alert"
        >
          <div className="flex items-center">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <p className="font-bold mb-0">{t('pages.contact.success')}</p>
          </div>
        </div>
      )}
    </div>
  );
}
