import useNavigation from 'hooks/useNavigation';
import { NavLink } from 'react-router-dom';

export default function Navigation() {
  const { navigation } = useNavigation();

  return (
    <nav>
      <ul className="flex gap-3 mt-5 justify-start | md:justify-between md:mt-0 md:gap-8 | xl:gap-20">
        {navigation.map((nav) => (
          <li key={nav.id}>
            <NavLink
              to={nav.link}
              className="font-cairo font-bold uppercase text-white text-base hover:text-yellow-500 | lg:text-xl"
            >
              {nav.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
