import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LastGameItem from 'components/molecules/LastGameItem';

export default function LastGamesList() {
  const { t } = useTranslation('global');
  const { userData } = useSelector((state) => state.user);

  const [divHeight, setDivHeight] = useState(0);

  const updateDivHeight = (height) => {
    setDivHeight(height);
  };

  return (
    <div id="lastGames" className="w-full mb-12">
      {userData?.lastGames?.length > 0 && (
        <>
          <h3 className="uppercase text-yellow-500 text-3xl mb-6 font-black">
            {t('pages.home.right-column.title')}
          </h3>
          <div
            className="last-games-list w-full mx-auto lg:w-10/12"
            style={{ maxHeight: `${divHeight * 4}px`, overflow: 'auto' }}
          >
            {userData?.lastGames?.map((item) => (
              <LastGameItem
                key={item.matchPublicId}
                data={item}
                updateHeight={updateDivHeight}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
