import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Loading } from 'pages';
import { useAlert } from 'react-alert';

import { setIsUserLoggedIn } from 'features/user/userSlice';

const LoginCallback = () => {
  const [searchParams] = useSearchParams();
  const login = searchParams.get('login');

  const alert = useAlert();

  const navigate = useNavigate();

  const isRunned = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isRunned.current) {
      isRunned.current = true;
      if (login === 'true') {
        localStorage.setItem('userLoggedIn', true);
        dispatch(setIsUserLoggedIn(true));
        setTimeout(() => {
          navigate('/user/home');
        }, 1000);
      } else if (login === 'false') {
        localStorage.setItem('userLoggedIn', false);
        dispatch(setIsUserLoggedIn(false));
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } else {
        alert.error('Internal error ocurred');
        setTimeout(() => {
          navigate('/');
        }, 1000);
      }
    }
    // eslint-disable-next-line
  }, []);

  return <Loading />;
};

export default LoginCallback;
