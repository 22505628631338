import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation('global');

  return (
    <div className="loading-screen w-full flex justify-center items-center bg-brown-700">
      <p className="text-yellow-600 text-3xl font-quantum">
        {t('pages.loading.message')}
      </p>
    </div>
  );
};

export default Loading;
