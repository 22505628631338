import { useRef, useState, useEffect } from 'react';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';
import { useDispatch } from 'react-redux';
import { setSelectedFriend } from 'features/friend/selectedFriendSlice';

export default function FriendItem(props) {
  const { data, updateHeight } = props;

  const [divHeight, setDivHeight] = useState(0);
  const divRef = useRef(null);

  const dispatch = useDispatch();

  const handleSelectFriend = (friend) => {
    dispatch(setSelectedFriend(friend));
  };

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const height = divRef.current.clientHeight;
        setDivHeight(height);
        updateHeight(height);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateHeight]);

  return (
    <article
      className="border rounded-[10px] overflow-hidden flex w-full mb-6 lg:mb-0"
      ref={divRef}
    >
      <div className="bg-[#171717] px-2 py-2 flex items-center justify-center">
        <div className="relative overflow-hidden rounded-full w-24 h-24 flex items-center">
          <Image src={data.userAvatar} type="cover" />
        </div>
      </div>
      <div className="py-2 px-2 flex flex-col justify-between grow h-32 relative">
        <div className="text-white uppercase">
          <h3
            className="text-sm font-black break-words"
            style={{
              display: 'block',
              overflowWrap: 'anywhere',
              paddingRight: '1.5rem',
            }}
          >
            {data.userName}
          </h3>
          <p className="text-sm break-words">{data.userTeam}</p>
        </div>
        <div className="flex justify-end">
          {data.online && (
            <Button
              variant="link"
              to="/user/play"
              onClick={() => handleSelectFriend(data)}
              type={data?.cta?.type}
              className="py-[0!important] px-[0!important]"
            >
              <span className="text-sm block py-1 px-4">{data?.cta?.name}</span>
            </Button>
          )}
        </div>
        {data.online ? (
          <div className="absolute top-2 right-2 w-[15px] h-[15px] min-w-[15px] rounded-full bg-green-500"></div>
        ) : (
          <div className="absolute top-2 right-2 w-[15px] h-[15px] min-w-[15px] rounded-full bg-red-400"></div>
        )}
      </div>
    </article>
  );
}
