import CardBg from 'components/atoms/CardBg';
import ReactApexChart from 'react-apexcharts';

export default function CardStats(props) {
  const { data } = props;

  return (
    <article className="uppercase font-black">
      <div className="relative">
        <span className="text-yellow-500 text-base absolute top-2 right-2 | md:top-3 md:right-4 | xl:right-5">
          {data.title}
        </span>
        <div>
          <CardBg />
        </div>
        <div className="absolute w-full h-full mx-auto top-0 left-0 right-0 flex justify-center items-center">
          <ReactApexChart
            options={data.params.options}
            series={data.params.series}
            type={data.params.type}
            height={'95%'}
            width={'100%'}
            className={'flex-shrink-0 w-full'}
          />
        </div>
      </div>
    </article>
  );
}
