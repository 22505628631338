import { useTranslation } from 'react-i18next';
import imageDemo1 from 'assets/images/wolf.png';
import imageDemo2 from 'assets/images/aries.png';
import Steam from 'components/atoms/icons/Steam';
import imageCompete from 'assets/images/bgCardCompete.jpg';
import imageStore from 'assets/images/bgCardStore.jpg';
import imgSteamPattern from 'assets/images/bg_steam_pattern.jpg';
import imgPlayVsFriendsObj from 'assets/images/play_vs_friends_obj.png';
import imgWaggies from 'assets/images/waggies.png';
import useStats from 'hooks/useStats';

export default function useHome() {
  const { t } = useTranslation('global');

  const {
    optionsKD,
    seriesKD,
    optionsWL,
    seriesWL,
    optionsWeapons,
    seriesWeapons,
  } = useStats();

  const headers = [
    {
      id: 0,
      cta: {
        id: 0.1,
        name: `${t('pages.ctas.compete-now')}`,
        type: 'primary',
        variant: 'link',
        url: '/user/play',
      },
      image: {
        src: imageCompete,
        alt: 'Compete Now',
      },
    },
    {
      id: 1,
      cta: {
        id: 0.2,
        name: `${t('pages.ctas.visit-store')}`,
        type: 'tertiary',
        variant: 'link',
        url: '/store',
      },
      image: {
        src: imageStore,
        alt: 'Visit Store',
      },
    },
  ];

  const friends = {
    title: `${t('pages.home.left-column.title')}`,
    // nodes:
    //   userData?.friends?.map((item) => ({
    //     ...item,
    //     userTeam: 'Team',
    //     cta: {
    //       id: 0.1,
    //       name: 'Invite',
    //       type: 'primary',
    //       variant: 'link',
    //       url: '/user/play',
    //     },
    //   })) || [],
    nodes: [
      {
        idPublic: '001',
        online: false,
        userAvatar: imageDemo1,
        userName: 'Aries Zodiac',
        userTeam: 'Mission Con',
        cta: {
          id: 0.1,
          name: 'Invite',
          type: 'primary',
          variant: 'link',
          url: '/user/play',
        },
      },
      {
        idPublic: '002',
        online: true,
        userAvatar: imageDemo2,
        userName: 'Wolf Ninja',
        userTeam: 'Team',
        cta: {
          id: 0.2,
          name: 'Invite',
          type: 'primary',
          variant: 'link',
          url: '/user/play',
        },
      },
      {
        idPublic: '003',
        online: false,
        userAvatar: imageDemo1,
        userName: 'Aries Zodiac',
        userTeam: 'Mission Con',
        cta: {
          id: 0.3,
          name: 'Invite',
          type: 'primary',
          variant: 'link',
          url: '/user/play',
        },
      },
      {
        idPublic: '004',
        online: true,
        userAvatar: imageDemo2,
        userName: 'Wolf Ninja',
        userTeam: 'Team',
        cta: {
          id: 0.4,
          name: 'Invite',
          type: 'primary',
          variant: 'link',
          url: '/user/play',
        },
      },
      {
        idPublic: '005',
        online: false,
        userAvatar: imageDemo1,
        userName: 'Aries Zodiac',
        userTeam: 'Mission Con',
        cta: {
          id: 0.4,
          name: 'Invite',
          type: 'primary',
          variant: 'link',
          url: '/user/play',
        },
      },
      {
        idPublic: '006',
        online: true,
        userAvatar: imageDemo2,
        userName: 'Wolf Ninja',
        userTeam: 'Team',
        cta: {
          id: 0.5,
          name: 'Invite',
          type: 'primary',
          variant: 'link',
          url: '/user/play',
        },
      },
    ],
  };

  const stats = {
    title: `${t('pages.home.center-column.statistics.title')}`,
    nodes: [
      {
        id: 0,
        title: 'K/D',
        params: {
          options: optionsKD,
          series: seriesKD,
          type: 'area',
        },
      },
      {
        id: 1,
        title: 'W/L',
        params: {
          options: optionsWL,
          series: seriesWL,
          type: 'area',
        },
      },
      {
        id: 2,
        title: 'Weapons',
        params: {
          options: optionsWeapons,
          series: seriesWeapons,
          type: 'bar',
        },
      },
    ],
  };

  const promotions = [
    {
      id: 0,
      title: `${t('pages.home.left-column.bottom-message')}`,
      type: 'primary',
      imageSecondary: {
        src: imgPlayVsFriendsObj,
        alt: '',
      },
      cta: {
        id: 0.1,
        name: `${t('pages.ctas.compete-now')}`,
        variant: 'link',
        type: 'primary',
        url: '/user/play',
      },
    },
    {
      id: 1,
      title: `${t('pages.home.left-column.buy-more-waggies')}`,
      type: 'secondary',
      imageSecondary: {
        src: imgWaggies,
        alt: '',
      },
      cta: {
        id: 0.1,
        name: `${t('pages.ctas.visit-store')}`,
        variant: 'link',
        type: 'secondary',
        url: '/store',
      },
    },
  ];

  const suscription = {
    id: 3,
    paragraph: `${t('pages.landing.advice.first')}`,
    subParagraph: `${t('pages.landing.advice.second')}`,
    imgPattern: {
      src: imgSteamPattern,
      alt: '',
    },
    imgLogo: <Steam />,
    cta: {
      id: 0.1,
      name: `${t('pages.landing.advice.cta')}`,
      variant: 'link',
      type: 'secondary',
      url: '/',
    },
  };

  return { headers, friends, stats, promotions, suscription };
}
