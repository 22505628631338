import { createSlice } from '@reduxjs/toolkit';

export const selectedFriendSlice = createSlice({
  name: 'selectedFriend',
  initialState: {
    friend: null,
  },
  reducers: {
    setSelectedFriend(state, action) {
      state.friend = action.payload;
    },
    clearSelectedFriend(state) {
      state.friend = null;
    },
  },
});

export const { setSelectedFriend, clearSelectedFriend } =
  selectedFriendSlice.actions;

export default selectedFriendSlice.reducer;
