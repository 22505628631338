import Container from 'components/atoms/Container';
import Image from 'components/atoms/Image';

export default function PlayToWinSection(props) {
  const { data } = props;

  return (
    <section className="w-full bg-[#1F2029] flex items-center justify-center relative pt-10 pb-10 | lg:pb-0">
      <Container className="order-2 max-w-screen-xl flex flex-wrap relative z-10">
        <h3 className="text-white text-shadow uppercase text-center border-2 border-white mx-auto px-5 py-2 mb-12 text-2xl xl:text-6xl xl:mb-16">
          <b>{data.title.first}</b>
          <span className="font-light inline-block mx-1">
            {data.title.second}
          </span>
          <b>{data.title.third}</b>
        </h3>
        <ul className="w-full grid grid-cols-1 gap-16 mb-20 | md:grid-cols-2 | 2xl:gap-20 xl:grid-cols-3">
          {data.items.map((item) => (
            <li key={item.id}>
              <article className="w-full text-white rounded-[10px] h-full bg-body-200 py-7 px-8 relative">
                <h3 className="text-lg uppercase font-black | lg:text-2xl">
                  {item.title}
                  <div className="border-b-2 border-yellow-500 w-4/5 my-2"></div>
                </h3>
                <p className="text-base font-light | xl:text-lg xl:leading-6">
                  {item.description}
                </p>
                <Image
                  src={item.image.src}
                  alt={item.image.alt}
                  className="absolute -bottom-12 -right-3 max-h-20 | xl:-right-12"
                />
              </article>
            </li>
          ))}
        </ul>
      </Container>
      <Image
        src={data.imageLeft.src}
        className="order-1 max-w-sm hidden mt-auto relative z-10 | lg:block"
      />
      <Image
        src={data.imageRight.src}
        className="order-3 max-w-sm hidden mt-auto relative z-10 | lg:block"
      />
      <div className="bg-gradient-to-t" />
    </section>
  );
}
