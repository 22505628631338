import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';

export default function CardMedium(props) {
  const { data } = props;

  return (
    <article
      className={`w-full text-white rounded-[10px] py-7 px-8 mb-8 relative | md:lg-0 ${
        data?.type === 'primary' ? 'bg-body-200' : 'bg-[#F6D872]'
      }`}
    >
      <h1
        className={`uppercase text-xl font-[900] mb-3 | md:text-[35px] md:mb-4 md:max-w-[300px] md:leading-[1.2] ${
          data?.type === 'primary' ? 'text-white' : 'text-black'
        }`}
      >
        {data.title}
      </h1>
      <div className="mt-10 flex items-center justify-between md:justify-start | md:gap-5 | lg:gap-8">
        <Button
          to={data.cta.url}
          variant={data.cta.variant}
          type={data.cta.type}
        >
          {data.cta.name}
        </Button>
      </div>
      <Image
        src={data.imageSecondary.src}
        className="absolute w-[160px] bottom-0 right-0 | md:w-[220px] | xl:w-[280px]"
      />
    </article>
  );
}
