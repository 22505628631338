import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useBanner from 'hooks/useBanner';
import Banner from 'components/templates/Banner';
import Container from 'components/atoms/Container';
import DatePicker from 'react-datepicker';
import Image from 'components/atoms/Image';
import 'react-datepicker/dist/react-datepicker.css';

export default function Privacy() {
  const { t } = useTranslation('global');
  const { register } = useBanner();
  console.log(register.imagePrimary.src);
  return (
    <div
      className="w-full bg-body-500"
      style={{
        background: `url(${register.imageBackground.src})`,
        backgroundSize: 'cover',
      }}
    >
      <Banner data={register} />
      <Container className="lg:max-w-[1440px] relative">
        <div className="flex flex-col gap-8 lg:flex-row lg:justify-end">
          <div className="max-w-[622px] w-full p-8 my-32 bg-[#0B0C11] rounded-lg">
            <h4 className="uppercase text-white font-black text-2xl text-center mb-8">
              {t('pages.register.subtitle')}
            </h4>
            <form className="form-register max-w-[340px] mx-auto">
              <div className="field flex z-10 h-12 mb-4">
                <label
                  id="name"
                  className="text-md inline-flex items-center clip-path-button px-4 py-3 font-[900] uppercase shrink-0 ease-linear leading-1 bg-yellow-500 text-black z-10 w-1/2 leading-3 justify-center"
                >
                  {t('pages.register.form.name')}
                </label>
                <input
                  name="name"
                  placeholder={t('pages.register.form.name')}
                  className="clip-path-button px-4 bg-black text-yellow-500 w-full border border-yellow-500 outline-none appearance-none pl-12 -ml-8"
                  value={''}
                  onChange={() => {}}
                  type="text"
                />
              </div>
              <div className="field flex z-10 h-12 mb-4">
                <label
                  id="name"
                  className="text-md inline-flex items-center clip-path-button px-4 py-3 font-[900] uppercase shrink-0 ease-linear leading-1 bg-yellow-500 text-black z-10 w-1/2 leading-3 justify-center"
                >
                  {t('pages.register.form.surname1')}
                </label>
                <input
                  name="name"
                  placeholder={t('pages.register.form.surname1')}
                  className="clip-path-button px-4 bg-black text-yellow-500 w-full border border-yellow-500 outline-none appearance-none pl-12 -ml-8"
                  value={''}
                  onChange={() => {}}
                  type="text"
                />
              </div>
              <div className="field flex z-10 h-12 mb-4">
                <label
                  id="name"
                  className="text-md inline-flex items-center clip-path-button px-4 py-3 font-[900] uppercase shrink-0 ease-linear leading-1 bg-yellow-500 text-black z-10 w-1/2 leading-3 justify-center"
                >
                  {t('pages.register.form.surname2')}
                </label>
                <input
                  name="name"
                  placeholder={t('pages.register.form.surname2')}
                  className="clip-path-button px-4 bg-black text-yellow-500 w-full border border-yellow-500 outline-none appearance-none pl-12 -ml-8"
                  value={''}
                  onChange={() => {}}
                  type="text"
                />
              </div>
              <div className="field flex z-10 h-12 mb-4">
                <label
                  id="name"
                  className="text-md inline-flex items-center clip-path-button px-4 py-3 font-[900] uppercase shrink-0 ease-linear leading-1 bg-yellow-500 text-black z-10 w-1/2 leading-3 justify-center"
                >
                  {t('pages.register.form.email')}
                </label>
                <input
                  name="name"
                  placeholder={t('pages.register.form.email')}
                  className="clip-path-button px-4 bg-black text-yellow-500 w-full border border-yellow-500 outline-none appearance-none pl-12 -ml-8"
                  value={''}
                  onChange={() => {}}
                  type="email"
                />
              </div>
              <div className="flex z-10 h-12 mb-4">
                <DatePicker
                  placeholderText={t('pages.register.form.birthday')}
                  dateFormat="DD/MM/YYYY"
                  selected={''}
                  onChange={() => {}}
                  showIcon
                />
              </div>
              <div className="field flex z-10 h-12 mb-4">
                <label
                  id="name"
                  className="text-md inline-flex items-center clip-path-button pl-1 py-3 font-[900] uppercase shrink-0 ease-linear leading-1 bg-yellow-500 text-black z-10 w-1/2 leading-3 text-center justify-center"
                >
                  {t('pages.register.form.code')}
                </label>
                <input
                  name="name"
                  placeholder={t('pages.register.form.code')}
                  className="clip-path-button px-4 bg-black text-yellow-500 w-full border border-yellow-500 outline-none appearance-none pl-12 -ml-8"
                  value={''}
                  onChange={() => {}}
                  type="number"
                />
              </div>
              <div className="w-full z-10 h-12 mb-4">
                <label
                  htmlFor="accept1"
                  className="inline-flex gap-2 items-center leading-none"
                >
                  <input type="checkbox" id="accept1" />
                  <span className="text-white uppercase">
                    {t('pages.register.form.accept1')}
                  </span>
                </label>
                <label
                  htmlFor="accept2"
                  className="inline-flex gap-2 items-center"
                >
                  <input type="checkbox" id="accept2" />
                  <span className="text-white uppercase leading-none">
                    {t('pages.register.form.accept2')}
                    <Link to="/" className="underline">
                      {t('pages.register.form.accept3')}
                    </Link>
                  </span>
                </label>
              </div>
              <p className="uppercase text-white text-xs">
                {t('pages.register.form.condition')}
              </p>
              <button
                className="form-register-submit w-full h-12 mt-6 uppercase bg-yellow-500 hover:bg-yellow-400 text-black font-bold"
                type="submit"
              >
                {t('pages.register.form.submit')}
              </button>
            </form>
          </div>
        </div>
        <Image
          src={register.imagePrimary.src}
          alt="bgRegister"
          className="register-image"
        />
      </Container>
    </div>
  );
}
