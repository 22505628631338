import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  messageConcat: undefined,
  type: null,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert(state, action) {
      state.message = action.payload.message;
      state.messageConcat = action.payload.messageConcat;
      state.type = action.payload.type;
    },
    clearAlert() {
      return initialState;
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;

export default alertSlice.reducer;
