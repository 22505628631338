export default function LastGameBg(props) {
  const { width, color } = props;

  return (
    <svg
      width={width || '100%'}
      height="100%"
      viewBox="0 0 630 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M588.329 5H428.853V1H590.376L629.195 54.8289L567.568 173.5H478.067L465.627 197.423L462.078 195.577L475.639 169.5H565.138L624.51 55.1711L588.329 5Z"
        fill={color || '#F2E60D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6976 1.5H162.853V5.5H89.0081L5.70569 149.924L19.4232 170.5H255.353V174.5H17.2825L1 150.076L86.6976 1.5Z"
        fill={color || '#F2E60D'}
      />
      <path
        d="M94.8528 14L20.3528 143.5L30 159L555.5 156L609 57.5L579 17L94.8528 14Z"
        fill="#1B1B20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M588.329 5H428.853V1H590.376L629.195 54.8289L567.568 173.5H478.067L465.627 197.423L462.078 195.577L475.639 169.5H565.138L624.51 55.1711L588.329 5Z"
        stroke="#1B1B20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6976 1.5H162.853V5.5H89.0081L5.70569 149.924L19.4232 170.5H255.353V174.5H17.2825L1 150.076L86.6976 1.5Z"
        stroke="#1B1B20"
      />
      <path
        d="M94.8528 14L20.3528 143.5L30 159L555.5 156L609 57.5L579 17L94.8528 14Z"
        stroke="#1B1B20"
      />
    </svg>
  );
}
