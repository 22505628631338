import Container from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';

export default function BannerPrincipal(props) {
  const { data } = props;

  return (
    <div className="w-full">
      <Container className="hero">
        <article className="bg-yellow-900 order-3 md:order-1 md:w-[40%] pl-[5%] pr-[3%] flex items-center py-8 relative hero__content">
          <div className="text-white uppercase font-extrabold leading-3 relative z-10">
            <span className="block text-xl tracking-[0.4rem] text-shadow | md:text-[1.82vw] md:tracking-[1vw] md:leading-[2vw] | xl:text-[26px] xl:leading-[1.2] mb-2">
              {data.minitag}
            </span>
            <h1 className="text-8xl leading-[66px] font-[900] text-shadow | md:text-[12vw] md:leading-[9vw] | xl:text-[180px] xl:leading-[0.7] title">
              {data.title}
            </h1>
            <span className="text-6xl font-[900] text-shadow | md:text-[7.32vw] | xl:text-[90px] xl:leading-[1] subtitle">
              {data.subtitle}
            </span>
            <p className="text-xl tracking-[1.2rem] text-shadow | md:text-[2.42vw] md:tracking-[2.38vw] | xl:text-[30px] xl:tracking-[40px]">
              {data.paragraph}
            </p>
            <div className="mt-10 flex items-center justify-between md:justify-start md: gap-3 | md:gap-5 | lg:gap-8">
              {data.cta.map((cta) => (
                <Button
                  key={cta.id}
                  to={cta.url}
                  variant={cta.variant}
                  type={cta.type}
                >
                  {cta.name}
                </Button>
              ))}
            </div>
          </div>
          <Image
            src={data.imagePattern.src}
            alt={data.imagePattern.alt}
            type="cover"
          />
        </article>
        <div className="h-[200px] relative order-2 overflow-hidden | md:w-[60%] md:h-[100%] hero__image">
          <Image
            src={data.imagePrimary.src}
            alt={data.imagePrimary.alt}
            type="cover"
          />
        </div>
      </Container>
    </div>
  );
}
