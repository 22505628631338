import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

export default function useCustomAlert() {
  const { t } = useTranslation('global');
  const { type, message, messageConcat } = useSelector((state) => state.alert);

  const alert = useAlert();

  const showAlert = useCallback(() => {
    if (type === 'error') {
      alert.error(
        `${messageConcat ? t(message) + ' ' + messageConcat : t(message)}`
      );
    } else if (type === 'success') {
      alert.success(
        `${messageConcat ? t(message) + ' ' + messageConcat : t(message)}`
      );
    } else if (type === 'info') {
      alert.info(
        `${messageConcat ? t(message) + ' ' + messageConcat : t(message)}`
      );
    }
  }, [alert, message, messageConcat, type, t]);

  return showAlert;
}
