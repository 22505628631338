import { useState, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import qs from 'qs';
import axios from 'axios';

import ParticlesOptions from 'data/ParticlesOptions.json';

export default function BuyWaggies() {
  const [moneyToBuy, setMoneyToBuy] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Creates the payment checkout with the backend
   */
  const createPaymentCheckout = async () => {
    const amount = parseFloat(moneyToBuy);
    if (isNaN(amount) || amount < 0.5) {
      setErrorMessage(
        'Please enter a valid amount of €0.5 or more to proceed.'
      );
      return;
    }

    axios
      .post('/stripe/create-checkout-session', qs.stringify({ moneyToBuy }))
      .then((data) => {
        // check if the url is available in the response and change the location of the window
        if (data.data.stripeCheckoutURl)
          window.open(data.data.stripeCheckoutURl, '_blank', 'noreferrer');
        else throw new Error('stripeCheckoutURl not available');
      })
      .catch((err) => {
        throw new Error(`Error creating payment checkout: ${err}`);
      });
  };

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setMoneyToBuy(value);
    if (!isNaN(parseFloat(value)) && parseFloat(value) >= 0.5) {
      setErrorMessage('');
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center overflow-y-scroll">
      <Particles
        className=" -z-50"
        id="tsparticles"
        options={ParticlesOptions}
        init={particlesInit}
      />
      <div className="flex flex-col items-center justify-start w-80 md:w-3/6 border rounded-md p-10 bg-white bg-opacity-80 shadow-xl">
        <p className="font-quantum text-4xl md:text-7xl mb-10 w-full">
          Mine more waggies
        </p>
        <div className="w-full rounded-md p-5 bg-white shadow-md bg-opacity-70">
          <div className="my-2 py-3">
            <label>
              <span>
                Waggies amount to buy (1WG = 0.1€). Insert the price in Euros:
              </span>
              <input
                type="number"
                value={moneyToBuy}
                onChange={handleChange}
                min="0.5"
                max="10000"
                step="0.1"
                className="mx-5 p-1 border rounded-sm bg-gray-300 bg-opacity-25"
              />
            </label>
          </div>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button
            type="submit"
            onClick={createPaymentCheckout}
            disabled={moneyToBuy < 0.5}
            className={`px-5 py-2 ${
              moneyToBuy < 0.5
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-600 duration-200 hover:duration-200'
            } rounded mt-5`}
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
}
