import BannerPrincipal from '../templates/BannerPrincipal';
import BannerMiddle from 'components/templates/BannerMiddle';
import BannerPromotional from 'components/templates/BannerPromotional';
import BannerLogin from 'components/templates/BannerLogin';
import Hero from 'components/templates/Hero';

const withBanner = (BaseComponent) => {
  const WithBannerComponent = ({ variant, children, ...props }) => {
    if (variant === 'principal') {
      return <BannerPrincipal {...props}>{children}</BannerPrincipal>;
    }

    if (variant === 'middle') {
      return <BannerMiddle {...props}>{children}</BannerMiddle>;
    }

    if (variant === 'promotional') {
      return <BannerPromotional {...props}>{children}</BannerPromotional>;
    }

    if (variant === 'logginWithSteam') {
      return <BannerLogin {...props}>{children}</BannerLogin>;
    }

    return <Hero {...props}>{children}</Hero>;
  };

  WithBannerComponent.displayName = `WithBanner(${getDisplayName(
    BaseComponent
  )})`;

  return WithBannerComponent;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withBanner;
