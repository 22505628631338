import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import axios from 'axios';
import { Provider } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import './assets/css/tailwind.css';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import globalEn from 'translations/en/global';
import globalEs from 'translations/es/global';

import store from 'app/store';

import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from 'components/atoms/ErrorBoundary';

i18next.init({
  interpolation: {
    excapeValue: false,
  },
  lng: 'en',
  resources: {
    en: {
      global: globalEn,
    },
    es: {
      global: globalEs,
    },
  },
});

// options for react alert
const alertOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 6000,
  offset: '5px',
  transition: transitions.SCALE,
};

// set default api url in axios
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

ReactDOM.createRoot(document.getElementById('root')).render(
  <HelmetProvider>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <BrowserRouter>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </BrowserRouter>
        </AlertProvider>
      </I18nextProvider>
    </Provider>
  </HelmetProvider>
);
