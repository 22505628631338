import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
export default function Layout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <main className="font-cairo overflow-x-hidden z-0">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
