export default function Steam(props) {
  const { width } = props;
  return (
    <svg
      width={`${width || '100%'}`}
      height="100%"
      viewBox="0 0 137 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.5 0.628906C31.0159 0.628906 0.628906 31.0159 0.628906 68.5C0.628906 105.984 31.0159 136.371 68.5 136.371C105.984 136.371 136.371 105.984 136.371 68.5C136.371 31.0159 105.984 0.628906 68.5 0.628906ZM91.6554 75.8667L68.7082 92.4512C67.8756 101.413 60.2108 108.427 50.9773 108.427C46.9211 108.43 42.9848 107.052 39.8157 104.52C36.6467 101.988 34.4336 98.4534 33.5404 94.4968L21.467 89.6994V67.3918L41.8674 75.5502C45.0511 73.6498 48.6273 72.7981 52.79 73.1636L67.6798 52.0252C67.7779 39.0692 78.48 28.5731 91.6066 28.5731C104.807 28.5731 115.509 39.1911 115.533 52.1957C115.533 65.2965 104.806 75.8667 91.6554 75.8667Z"
        fill="black"
      />
      <path
        d="M50.9773 77.7177C50.0214 77.7147 49.0683 77.8209 48.1365 78.0343L53.5979 80.226C54.8654 80.719 56.0223 81.4588 57.0017 82.4024C57.981 83.346 58.7633 84.4746 59.3032 85.7228C59.8431 86.971 60.1298 88.314 60.1468 89.6738C60.1638 91.0337 59.9106 92.3833 59.402 93.6446C58.8866 94.9138 58.1258 96.0689 57.1634 97.0438C56.2009 98.0186 55.0556 98.794 53.7931 99.3256C52.5305 99.8572 51.1755 100.134 49.8057 100.142C48.4358 100.149 47.0779 99.8858 45.8099 99.3675C43.6793 98.5395 41.4998 97.6384 39.3692 96.8348C40.4378 98.9012 42.0375 100.646 44.0037 101.889C45.97 103.132 48.2318 103.83 50.5568 103.909C52.8819 103.989 55.1861 103.448 57.2328 102.342C59.2794 101.236 60.9946 99.6049 62.202 97.6164C63.4095 95.6279 64.0656 93.3537 64.1029 91.0276C64.1403 88.7016 63.5575 86.4075 62.4145 84.3813C61.2715 82.355 59.6096 80.6697 57.5995 79.4986C55.5894 78.3275 53.3037 77.7128 50.9773 77.7177Z"
        fill="black"
      />
      <path
        d="M91.6308 36.3661C82.741 36.3661 75.5654 43.4774 75.5654 52.2208C75.5654 61.0123 82.7654 68.0755 91.6308 68.0755C100.423 68.0999 107.648 61.0131 107.623 52.2208C107.623 43.4774 100.423 36.3661 91.6308 36.3661ZM91.5941 61.7415C89.7153 61.7214 87.8843 61.1459 86.3319 60.0874C84.7794 59.029 83.5748 57.5349 82.8697 55.7932C82.1646 54.0516 81.9906 52.1403 82.3694 50.2999C82.7483 48.4596 83.6632 46.7725 84.9989 45.451C86.3346 44.1295 88.0314 43.2327 89.8757 42.8735C91.72 42.5143 93.6293 42.7088 95.3633 43.4325C97.0973 44.1562 98.5785 45.3767 99.6203 46.9404C100.662 48.5041 101.218 50.341 101.218 52.22C101.213 53.4777 100.961 54.7221 100.474 55.8819C99.9877 57.0417 99.277 58.0941 98.3829 58.9787C97.4889 59.8633 96.429 60.5627 95.264 61.0368C94.0991 61.5109 92.8518 61.7503 91.5941 61.7415Z"
        fill="black"
      />
    </svg>
  );
}
