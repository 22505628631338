import { useTranslation } from 'react-i18next';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const { t } = useTranslation('global');
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div
      className="flex justify-end items-center space-x-2 mt-20"
      data-testid="pagination"
    >
      <button
        className="bg-[#4B4B4B] text-yellow-500 disabled:bg-gray-400 disabled:text-gray-600 py-[6px!important] md:py-[12px!important] px-[10px!important] md:px-[24px!important] clip-path-button font-bold uppercase text-base"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        data-testid="pagination-back"
      >
        {t('pages.ctas.back')}
      </button>
      {pageNumbers.map((pageNumber) => {
        if (
          pageNumber === 1 ||
          pageNumber === totalPages ||
          (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
        ) {
          return (
            <button
              type="button"
              key={pageNumber}
              className={`${
                pageNumber === currentPage
                  ? 'bg-yellow-500 text-black py-[6px!important] md:py-[12px!important] px-[10px!important] md:px-[24px!important] clip-path-button font-bold uppercase text-base'
                  : 'bg-[#4B4B4B] text-yellow-500 py-[6px!important] md:py-[12px!important] px-[10px!important] md:px-[24px!important] clip-path-button font-bold uppercase text-base'
              } `}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        } else if (
          pageNumber === currentPage - 2 ||
          pageNumber === currentPage + 2
        ) {
          return (
            <span
              key={pageNumber}
              className="px-1 py-1 text-white block tracking-widest"
            >
              ...
            </span>
          );
        } else {
          return null;
        }
      })}
      <button
        type="button"
        className="bg-[#4B4B4B] text-yellow-500 disabled:bg-gray-400 disabled:text-gray-600 py-[6px!important] md:py-[12px!important] px-[10px!important] md:px-[24px!important] clip-path-button font-bold uppercase text-base"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        data-testid="pagination-next"
      >
        {t('pages.ctas.next')}
      </button>
    </div>
  );
}
