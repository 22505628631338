import { useEffect, useState } from 'react';
import Select from 'react-select';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { MatchRequestItem } from 'components';
import { createMatchRequest } from 'app/socketConnection';

const PlaySubscreen = () => {
  const { userData } = useSelector((state) => state.user); // pick the user from the redux store
  // variable for setting what type of game the user wants
  const [gameType, setGameType] = useState(0);

  const { t } = useTranslation('global');

  const alert = useAlert();

  const [usersAvailableToPlay, setUsersAvailableToPlay] = useState([]); // users to play
  const [selectedUserToPlayWith, setSelectedUserToPlayWith] = useState(null); // selected user to play with in customized game
  const [waggiesToBet, setWaggiesToBet] = useState(''); // amount of waggies to bet

  useEffect(() => {
    if (userData && userData.friends) {
      let auxArray = [];
      for (const user of userData.friends) {
        if (user.online)
          auxArray = [
            ...auxArray,
            {
              value: user.idPublic,
              label: user.userName,
              online: true,
            },
          ];
      }
      setUsersAvailableToPlay(auxArray);
    }
  }, [userData]);

  const { pathname } = useLocation();
  useEffect(() => {
    ReactGa.pageview(pathname); // set what page is being seen
  }, [pathname]);

  const createMatch = () => {
    const matchData = {};
    matchData.action = 'newMatchRequest'; // set message to create a new match
    matchData.data = {};
    let amountToBet;
    // see the game type for configuring the match
    if (gameType === 0) {
      // customized game

      // set the game type to send
      matchData.data.gameType = 'customized';

      // check that a user to play with has been selected
      if (
        selectedUserToPlayWith === null ||
        selectedUserToPlayWith === undefined
      ) {
        alert.error('Select a user to play with');
        return;
      }

      // add the user to play with
      matchData.data.secondPlayer = {};
      matchData.data.secondPlayer.publicId = selectedUserToPlayWith.value;

      // check wagies to bet
      amountToBet = parseInt(waggiesToBet);
      if (!isNaN(amountToBet)) {
        matchData.data.amountOfWaggies = amountToBet;
      } else {
        alert.error('Error with the waggies ammount');
        return;
      }
    } else if (gameType === 1) {
      // matchmaking game

      // set the game type to send
      matchData.data.gameType = 'random';

      // check wagies to bet
      amountToBet = parseInt(waggiesToBet);
      if (!isNaN(amountToBet)) {
        matchData.data.amountOfWaggies = amountToBet;
      } else {
        alert.error('Error with the waggies ammount');
        return;
      }
    } else {
      // in case an error ocurres with the gameType
      return;
    }

    createMatchRequest(matchData);
  };

  /**
   * Helper for updating the value of waggiesToBet
   * @param {*} event
   */
  const updateWaggiesValue = (event) => {
    setWaggiesToBet(event.target.value);
  };

  return (
    <div className="hidden">
      {/* Top container */}
      <div className="bg-brown-800 px-10 pt-5 pb-16 mb-16">
        {/* Personalized or Random buttons */}
        <div className="flex my-4 space-x-3">
          {/* Check what the selection of the user is to return the button with the color selected */}
          {gameType === 0 ? (
            <button className="group" onClick={() => setGameType(0)}>
              <p className="border-solid px-6 py-2 border-2 rounded-sm text-yellow-200 border-yellow-200 hover:text-yellow-600 hover:border-yellow-600 hover:duration-700 duration-700">
                {t(
                  'pages.play-subscreen.top-section.buttons.personalized.text'
                )}
              </p>

              {t('pages.play-subscreen.top-section.buttons.random.text')}
            </button>
          ) : (
            <div></div>
          )}
          {gameType === 1 ? (
            <button className="group" onClick={() => setGameType(1)}>
              <p className="border-solid px-6 py-2 border-2 rounded-sm text-yellow-200 border-yellow-200 hover:text-yellow-600 hover:border-yellow-600 hover:duration-700 duration-700">
                {t('pages.play-subscreen.top-section.buttons.random.text')}
              </p>
            </button>
          ) : (
            <div></div>
          )}
        </div>
        {/* User id section. Only shows when Personalized game is selected */}
        <div>
          {gameType === 0 ? (
            <div className="my-9">
              <p className="text-gray-200 text-xl">
                {t('pages.play-subscreen.top-section.game.play-with.title')}
              </p>
              <Select
                closeMenuOnSelect={true}
                options={usersAvailableToPlay}
                className="bg-brown-800 text-gray-400 max-w-sm"
                isSearchable={true}
                placeholder={t(
                  'pages.play-subscreen.top-section.game.play-with.selection.text'
                )}
                isClearable={true}
                onChange={setSelectedUserToPlayWith}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {/* Bet section */}
        <div className="my-7">
          <p className="text-white text-xl">
            {t('pages.play-subscreen.top-section.game.bet-wagies.title')}
          </p>
          <input
            name="wagiesToBet"
            placeholder="200"
            className="bg-brown-800 text-gray-400 mr-5 px-2 py-1 border border-white outline-none"
            value={waggiesToBet}
            onChange={updateWaggiesValue}
            type="number"
            step="1"
            pattern="\d+"
          />
        </div>
        {/* Play section */}
        <div className="mt-4">
          <button
            className="group border-solid border-2 rounded-sm flex w-full justify-center py-4 hover:bg-white hover:duration-300 duration-300"
            onClick={createMatch}
          >
            <p className="block group-hover:text-black text-white hover:duration-500">
              {t('pages.play-subscreen.top-section.game.play-button.text')}
            </p>
          </button>
        </div>
      </div>
      {/* Match request section */}
      <div className="yellowCorners mb-6">
        <div className="bg-brown-800 pt-10 pb-4 border-2 border-opacity-25 border-yellow-500">
          <div className="px-10">
            <p className="text-yellow-400 text-3xl">
              {t('pages.play-subscreen.match-requests.title')}
            </p>
          </div>
          {/* Games list */}
          <div className="mt-6 min-h-96 max-h-[260px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500">
            {userData?.matchRequests?.length !== 0 ? (
              <div>
                {userData?.matchRequests?.map((element, index) => (
                  <MatchRequestItem key={index} match={element} />
                ))}
              </div>
            ) : (
              <div className="">
                <p className="text-gray-400 italic">
                  {t(
                    'pages.play-subscreen.match-requests.no-match-requests-message'
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Last games section */}
      <div className="yellowCorners mb-6">
        <div className="bg-brown-800 pt-10 pb-4 border-2 border-opacity-25 border-yellow-500">
          <div className="px-10">
            <p className="text-yellow-400 text-3xl">
              {t('pages.play-subscreen.bottom-section.title')}
            </p>
          </div>
          {/* Games list */}
          <div className="mt-6 min-h-96 max-h-[260px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500">
            {userData?.lastGames?.length !== 0 ? (
              <h1>Listado lastGamesItems</h1>
            ) : (
              // <LastGamesItems lastGames={userData?.lastGames} />
              <div className="">
                <p className="text-gray-400 italic">
                  {t('pages.play-subscreen.bottom-section.no-games-message')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaySubscreen;
