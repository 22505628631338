import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'components/atoms/Container';
import Banner from 'components/templates/Banner';
import Card from 'components/molecules/Card';
import Pagination from 'components/molecules/Pagination';
import useBanner from 'hooks/useBanner';
import useWeapon from 'hooks/useWeapon';
import Grid from 'components/atoms/Grid';

export default function Store() {
  const { t } = useTranslation('global');
  const { store } = useBanner();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [columns, setColumns] = useState(2);

  const { weapons, weaponsFilter, total } = useWeapon(
    currentPage,
    itemsPerPage,
    selectedCategories
  );

  const ListTags = Array.from(new Set(weaponsFilter));

  const toggleCategory = (value) => {
    if (selectedCategories.includes(value)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== value)
      );
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
    setCurrentPage(0);
  };

  const filteredWeapons = weapons?.filter(
    (weapon) =>
      selectedCategories.length === 0 ||
      selectedCategories.some((category) => weapon.name.includes(category))
  );

  const totalPages = Math.ceil(total / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  useEffect(() => {
    const widthSettings = {
      default: { itemsPerPage: 10, columns: 2 },
      768: { itemsPerPage: 10, columns: 2 },
      988: { itemsPerPage: 12, columns: 3 },
      1328: { itemsPerPage: 12, columns: 4 },
      1666: { itemsPerPage: 10, columns: 5 },
    };
    const handleResize = () => {
      const width = window.innerWidth;

      let settings = widthSettings.default;

      if (width >= 1666) {
        settings = widthSettings[1666];
      } else if (width >= 1328) {
        settings = widthSettings[1328];
      } else if (width >= 988) {
        settings = widthSettings[988];
      } else if (width < 768) {
        settings = widthSettings[768];
      }

      setItemsPerPage(settings.itemsPerPage);
      setColumns(settings.columns);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setItemsPerPage, setColumns]);

  return (
    <div className="w-full bg-body-500" data-testid="store-component">
      <Banner data={store} />
      <Container className="pb-12 pt-8 | lg:pb-20 lg:pt-20">
        <div className="bg-[#414141] rounded-lg py-6 px-6 mb-12">
          <h4 className="uppercase text-white font-black mb-4">Weapons</h4>
          <ul className="flex flex-wrap gap-2">
            {ListTags?.map((weapon, index) => (
              <li key={`${index * Math.random()}`}>
                <button
                  className="bg-[#494949] py-[0!important] px-[0!important] clip-path-button font-bold uppercase text-base"
                  onClick={() => {
                    toggleCategory(weapon);
                  }}
                  data-testid={`category-${weapon}`}
                >
                  <span
                    className={`text-white flex text-sm py-1 px-3 lg:text-sm lg:px-4 lg:py-2 xl:text-sm xl:leading-3 ${
                      selectedCategories.includes(weapon)
                        ? 'bg-yellow-500 text-[black!important]'
                        : 'bg-[#494949] text-white'
                    }`}
                  >
                    {weapon}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
        {filteredWeapons?.length > 0 && (
          <>
            <Grid
              data-testid="grid"
              className="store__list grid grid-cols-auto-fill-minmax gap-x-10 gap-y-12"
              style={{
                '--min-col-width': `${Math.floor(100 / columns)}%`,
              }}
            >
              {filteredWeapons?.slice(0, itemsPerPage).map((weapon) => (
                <Card
                  key={`${weapon.id}-${weapon.name}`}
                  data-testid={`${weapon.id}`}
                  data={weapon}
                />
              ))}
            </Grid>

            <Pagination
              currentPage={currentPage + 1}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              data-testid="pagination"
            />
          </>
        )}
        {filteredWeapons?.length === 0 && filteredWeapons !== undefined && (
          <p
            data-testid="no-weapons-message"
            className="flex justify-center text-center text-white py-16 text-lg"
          >
            {t('pages.store.no-weapons.message')}
          </p>
        )}
      </Container>
    </div>
  );
}
