import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setAlert } from 'features/alert/alertSlice';

import store from 'app/store';

const withButton = (BaseComponent) => {
  const WithButtonComponent = ({ variant, children, ...props }) => {
    const navigate = useNavigate();
    if (variant === 'link') {
      return (
        <Link
          {...props}
          className={`
          text-base inline-block clip-path-button px-5 py-3 font-[900] uppercase | lg:text-xl lg:px-7 lg:py-4 ease-linear overflow-hidden
          ${
            props?.type !== undefined
              ? props?.type === 'primary' &&
                'bg-yellow-500 text-black hover:bg-black hover:text-yellow-500'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'secondary' &&
                'bg-black text-yellow-500 hover:bg-yellow-500 hover:text-black'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'tertiary' &&
                'bg-white text-black hover:bg-black hover:text-white'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'outline' &&
                'bg-black border border-yellow-500 text-yellow-500 relative after:w-2/4 after:h-[4px] after:absolute after:bottom-[0] after:left-[-18%] after:rotate-[43deg] after:bg-yellow-500 before:w-2/4 before:h-[4px] before:absolute before:top-[0] before:right-[-18%] before:rotate-[43deg] before:bg-yellow-500 hover:bg-yellow-500 hover:text-black'
              : ''
          }
          ${props?.className !== undefined ? props.className : ''}
          `}
        >
          <span className="block leading-4">
            {children || 'Default link text'}
          </span>
        </Link>
      );
    }
    if (variant === 'outside-link') {
      return (
        <button
          {...props}
          onClick={() => {
            window.location = props.to;
          }}
          className={`
          text-base inline-block clip-path-button px-5 py-3 font-[900] uppercase | lg:text-xl lg:px-7 lg:py-4 ease-linear overflow-hidden
          ${
            props?.type !== undefined
              ? props?.type === 'primary' &&
                'bg-yellow-500 text-black hover:bg-black hover:text-yellow-500'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'secondary' &&
                'bg-black text-yellow-500 hover:bg-yellow-500 hover:text-black'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'tertiary' &&
                'bg-white text-black hover:bg-black hover:text-white'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'outline' &&
                'bg-black border border-yellow-500 text-yellow-500 relative after:w-2/4 after:h-[4px] after:absolute after:bottom-[0] after:left-[-18%] after:rotate-[43deg] after:bg-yellow-500 before:w-2/4 before:h-[4px] before:absolute before:top-[0] before:right-[-18%] before:rotate-[43deg] before:bg-yellow-500 hover:bg-yellow-500 hover:text-black'
              : ''
          }
          ${props?.className !== undefined ? props.className : ''}
          `}
        >
          <span className="block leading-4">
            {children || 'Default link text'}
          </span>
        </button>
      );
    }

    if (variant === 'confirm-purchase') {
      return (
        <button
          {...props}
          onClick={() => {
            axios
              .post('/store/buy-weapon', {
                weapons: [props?.weapon_id],
              })
              .then((data) => {
                /*
                  alert(`Compra hecha del arma '${props?.weapon_id}'.`);
                */
                store.dispatch(
                  setAlert({
                    message: 'alert.purchase-made-of-the-weapon',
                    type: 'success',
                  })
                );
                navigate('/store');
              })
              .catch((err) => {
                store.dispatch(
                  setAlert({
                    message: 'alert.error-creating-payment-checkout',
                    type: 'error',
                  })
                );
                navigate('/store');
                throw new Error(`Error creating payment checkout: ${err}`);
              });
          }}
          className={`
          text-base inline-block clip-path-button px-5 py-3 font-[900] uppercase | lg:text-xl lg:px-7 lg:py-4 ease-linear overflow-hidden
          ${
            props?.type !== undefined
              ? props?.type === 'primary' &&
                'bg-yellow-500 text-black hover:bg-black hover:text-yellow-500'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'secondary' &&
                'bg-black text-yellow-500 hover:bg-yellow-500 hover:text-black'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'tertiary' &&
                'bg-white text-black hover:bg-black hover:text-white'
              : ''
          }
          ${
            props?.type !== undefined
              ? props?.type === 'outline' &&
                'bg-black border border-yellow-500 text-yellow-500 relative after:w-2/4 after:h-[4px] after:absolute after:bottom-[0] after:left-[-18%] after:rotate-[43deg] after:bg-yellow-500 before:w-2/4 before:h-[4px] before:absolute before:top-[0] before:right-[-18%] before:rotate-[43deg] before:bg-yellow-500 hover:bg-yellow-500 hover:text-black'
              : ''
          }
          ${props?.className !== undefined ? props.className : ''}
          `}
        >
          <span className="block leading-4">
            {children || 'Default link text'}
          </span>
        </button>
      );
    }

    return (
      <Link
        {...props}
        type="button"
        className={`
        text-base inline-block clip-path-button px-5 py-3 font-[900] uppercase | lg:text-xl lg:px-7 lg:py-4 ease-linear overflow-hidden
        ${
          props?.type !== undefined
            ? props?.type === 'primary' &&
              'bg-yellow-500 text-black hover:bg-black hover:text-yellow-500'
            : ''
        }
        ${
          props?.type !== undefined
            ? props?.type === 'secondary' &&
              'bg-black text-yellow-500 hover:bg-yellow-500 hover:text-black'
            : ''
        }
        ${
          props?.type !== undefined
            ? props?.type === 'tertiary' &&
              'bg-white text-black hover:bg-black hover:text-white'
            : ''
        }
        ${
          props?.type !== undefined
            ? props?.type === 'outline' &&
              'bg-black border border-yellow-500 text-yellow-500 relative after:w-2/4 after:h-[4px] after:absolute after:bottom-[0] after:left-[-18%] after:rotate-[43deg] after:bg-yellow-500 before:w-2/4 before:h-[4px] before:absolute before:top-[0] before:right-[-18%] before:rotate-[43deg] before:bg-yellow-500 hover:bg-yellow-500 hover:text-black'
            : ''
        }
        ${props?.className !== undefined ? props.className : ''}
        `}
      >
        <span className="block leading-4">
          {children || 'Default link text'}
        </span>
      </Link>
    );
  };

  WithButtonComponent.displayName = `WithButton(${getDisplayName(
    BaseComponent
  )})`;

  return WithButtonComponent;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withButton;
