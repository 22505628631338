import { startLoadingWeapons, setWeapons } from 'features/weapons/weaponsSlice';

export const getWeapons = (page = 0, pageSize = 10, names = []) => {
  return async (dispatch, getState) => {
    dispatch(startLoadingWeapons());

    try {
      const queryString = names
        .map((name) => `name=${encodeURIComponent(name)}`)
        .join('&');
      const url = `${process.env.REACT_APP_BACKEND_URL}/store?pageNumber=${page}&pageSize=${pageSize}&${queryString}`;

      const resp = await fetch(url);

      if (!resp.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await resp.json();

      dispatch(
        setWeapons({
          weapons: data,
          page: page + 1,
          names,
        })
      );
    } catch (error) {
      console.error('Failed to fetch weapons:', error);

      dispatch(
        setWeapons({
          weapons: { rows: [] },
          page,
        })
      );
    }
  };
};
