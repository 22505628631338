import { Component } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(
      'c% ErrorBoundary caught an error',
      'background:red;color:white;font-weight:bold',
      error
    );
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/" replace />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
