import { useTranslation } from 'react-i18next';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';
import CardBg from 'components/atoms/icons/CardBg';
import { useNavigate } from 'react-router-dom';

export default function CardProduct(props) {
  const { data, className } = props;
  const { t } = useTranslation('global');
  const navigate = useNavigate();

  const handleNavigation = async (event) => {
    event.preventDefault();

    try {
      navigate(`/store/${data.id}`, { state: { data } });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <article
      data-testid={`weapon-${data?.id}`}
      className={`uppercase font-black flex flex-col ${className}`}
    >
      <div className="relative flex flex-col">
        <CardBg />
        <div className="card-store__tag">
          <span>{data.quality}</span>
        </div>
        <div className="product__image relative w-full h-full mx-auto py-8 flex justify-center items-center">
          {data?.image && (
            <Image
              className="max-w-md w-full block px-10 mx-auto"
              src={data.image}
              alt={data.name}
            />
          )}
          {data?.imageSeal && (
            <Image
              src={data?.imageSeal}
              width="58px"
              className="absolute bottom-[5%] left-[4%]"
            />
          )}
        </div>
      </div>
      <div className="flex justify-between items-start text-lg my-4 ">
        <h3 className="text-white leading-5">{data.name}</h3>
        <b className="text-yellow-500 leading-5">{`${data.price}W`}</b>
      </div>
      <div className="flex justify-between items-center text-lg mt-auto">
        {props?.showBuyButton === false ? (
          <></>
        ) : (
          <Button
            type="primary"
            state={{ data }}
            className="ml-auto min-w-[120px] text-center"
            onClick={handleNavigation}
          >
            {t('pages.store.cart.buy-button.text')}
          </Button>
        )}
      </div>
    </article>
  );
}
