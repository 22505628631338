import imageStatTrack from 'assets/images/stat-track.png';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWeapons } from 'features/weapons/thunks';

export default function useWeapon(numberPage, numberPageSize, names) {
  const dispatch = useDispatch();
  const { weapons: data } = useSelector((state) => state?.weapons);

  useEffect(() => {
    dispatch(getWeapons(numberPage, numberPageSize, names));
  }, [dispatch, numberPage, numberPageSize, names]);

  const weapons = data?.rows?.map((weapon) => ({
    id: weapon.id,
    name: weapon.weaponName,
    image: weapon.imageUrl,
    price: weapon.sellPrice,
    category: weapon.category,
    exterior: weapon.exterior,
    quality: weapon.quality,
    imageSeal: weapon.category.includes('StatTrak')
      ? imageStatTrack
      : undefined,
  }));

  const total = data?.count;

  const weaponsFilter = [
    'CZ75-Auto',
    'Desert Eagle',
    'Dual Berettas',
    'Five-SeveN',
    'Glock-18',
    'P2000',
    'P250',
    'R8 Revolver',
    'Tec-9',
    'USP-S',
    'AK-47',
    'AUG',
    'AWP',
    'FAMAS',
    'G3SG1',
    'Galil AR',
    'M4A1-S',
    'M4A4',
    'SCAR-20',
    'SG 553',
    'SSG 08',
    'MAC-10',
    'MP5-SD',
    'MP7',
    'MP9',
    'PP-Bizon',
    'P90',
    'UMP-45',
    'MAG-7',
    'Nova',
    'Sawed-Off',
    'XM1014',
    'M249',
    'Negev',
    'Knife',
  ];

  return { weapons, weaponsFilter, total };
}
