import CardSimple from '../templates/CardSimple';
import CardMedium from 'components/templates/CardMedium';
import CardStats from 'components/templates/CardStats';
import CardProduct from 'components/templates/CardProduct';

const withCard = (BaseComponent) => {
  const WithCardComponent = ({ variant, children, ...props }) => {
    if (variant === 'basic') {
      return <CardSimple {...props}>{children}</CardSimple>;
    }

    if (variant === 'medium') {
      return <CardMedium {...props}>{children}</CardMedium>;
    }

    if (variant === 'stats') {
      return <CardStats {...props}>{children}</CardStats>;
    }

    return <CardProduct {...props}>{children}</CardProduct>;
  };

  WithCardComponent.displayName = `WithCard(${getDisplayName(BaseComponent)})`;

  return WithCardComponent;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withCard;
