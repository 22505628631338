import { useTranslation } from 'react-i18next';
import bgContact from 'assets/images/img_contact.png';
import useBanner from 'hooks/useBanner';
import Banner from 'components/templates/Banner';
import Container from 'components/atoms/Container';
import Image from 'components/atoms/Image';

export default function Cookies() {
  const { t } = useTranslation('global');
  const { cookies } = useBanner();

  const body = t('pages.cookies.content');

  return (
    <div className="w-full bg-body-500">
      <Banner data={cookies} />
      <Container className="lg:max-w-[1440px]">
        <div className="lg:flex my-12 justify-center md:gap-12">
          <div className="lg:flex flex-col lg:w-[60%] self-center flex-grow">
            <div className="w-full text-white terms md:pr-8">
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          </div>
          <Image
            src={bgContact}
            className="lg:max-w-[18rem] object-contain mt-auto"
          />
        </div>
      </Container>
    </div>
  );
}
