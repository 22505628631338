import { configureStore } from '@reduxjs/toolkit';

import userSlice from 'features/user/userSlice';
import featuredVideosSlice from 'features/featuredVideos/featuredVideosSlice';
import weaponsSlice from 'features/weapons/weaponsSlice';
import alertSlice from 'features/alert/alertSlice';
import selectedFriendSlice from 'features/friend/selectedFriendSlice';
import matchRequestSlice from 'features/matchRequest/matchRequestSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    featuredVideos: featuredVideosSlice,
    weapons: weaponsSlice,
    alert: alertSlice,
    selectedFriend: selectedFriendSlice,
    matchRequest: matchRequestSlice,
  },
});

export default store;
