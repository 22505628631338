import { createSlice } from '@reduxjs/toolkit';

export const weaponsSlice = createSlice({
  name: 'weapons',
  initialState: {
    page: 0,
    pageSize: 10,
    weapons: [],
    isLoading: false,
    names: [],
  },
  reducers: {
    startLoadingWeapons: (state /* actions */) => {
      state.isLoading = true;
    },
    setWeapons: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.weapons = action.payload.weapons;
      state.names = action.payload.names;
    },
  },
});

export const { startLoadingWeapons, setWeapons } = weaponsSlice.actions;

export default weaponsSlice.reducer;
