import Banner from 'components/templates/Banner';
import FeaturedWeapons from 'components/organisms/landing/FeaturedWeapons';
import useBanner from 'hooks/useBanner';
import { useLocation, useNavigate } from 'react-router-dom';
import useSection from 'hooks/useSection';
import Card from 'components/molecules/Card';
import Container from 'components/atoms/Container';
import Grid from 'components/atoms/Grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/atoms/Button';
import { useAlert } from 'react-alert';

export default function WeaponDetail() {
  const { store: storeBanner } = useBanner();
  const location = useLocation();
  const { featuredWeapons } = useSection();
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();
  const alert = useAlert();
  const [data] = useState(location?.state?.data);

  useEffect(() => {
    if (!data) {
      if (i18n.language === 'es') {
        alert.error('Arma no encontrada');
      } else {
        alert.error('Weapon not found');
      }
      navigate('/');
    } else {
      const body = document.querySelector('#root');
      body.scrollIntoView({ behavior: 'smooth' }, 500);
    }
  }, [data, i18n.language, alert, navigate]);

  if (!data) {
    return null;
  }

  return (
    <div className="w-full bg-body-500">
      <Banner data={storeBanner} />
      <Container>
        <Grid className="mt-20 grid-cols-1 | md:grid-cols-2 md:gap-4">
          <div className="max-w-[460px] detail-wrapper xl:mx-auto xl:w-full">
            <Card showBuyButton={false} data={data} />
          </div>
          <div className="max-w-2xl">
            <h2 className="font-black text-5xl text-white">{data.name}</h2>
            <h3 className="mt-10 text-white text-3xl font-black">
              {data.price}
              <span>W</span>
              <span className="text-yellow-500 font-thin mx-4">
                ({t('pages.weapon-detail-screen.in-stock')})
              </span>
            </h3>
            <p className="mt-20 text-gray-300 text-xl">
              {t('pages.weapon-detail-screen.buing-steps')}
            </p>
            <Button
              variant="confirm-purchase"
              weapon_id={data.id}
              type="primary"
              className="mt-12 ml-auto min-w-[120px]"
            >
              {t('pages.weapon-detail-screen.confirm-purchase')}
            </Button>
          </div>
        </Grid>
      </Container>
      <FeaturedWeapons data={featuredWeapons} />
    </div>
  );
}
