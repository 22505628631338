import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useHome from 'hooks/useHome';
import { FriendItem, FriendRequest } from 'components';
import { makeNewFriendshipRequest } from 'app/socketConnection';
import FriendsSubscreen from 'pages/FriendsSubscreen/FriendsSubscreen';
import Container from 'components/atoms/Container';
import Button from 'components/atoms/Button';
import Card from 'components/molecules/Card';
import CurrentUser from 'components/molecules/CurrentUser';
import FeaturedVideos from 'components/organisms/landing/FeaturedVideos';
import useSection from 'hooks/useSection';

export default function Friends() {
  const { t } = useTranslation('global');
  const { userData } = useSelector((state) => state.user);
  const [friendRequestUserId, setFriendRequestUserId] = useState('');
  const [friendView, setFriendView] = useState(0);
  const { promotions } = useHome();
  const { featuredVideos } = useSection();

  const onlineFriendList = [];
  const offlineFriendList = [];

  const sendNewFriendRequest = () => {
    makeNewFriendshipRequest(friendRequestUserId);
    setFriendRequestUserId('');
  };

  const handleKeyPressed = (e) => {
    if (e.key === 'Enter') sendNewFriendRequest();
  };

  // Check if userData and userData.friends are defined
  if (userData && Array.isArray(userData.friends)) {
    for (const friend of userData.friends) {
      if (friend.online) onlineFriendList.push(friend);
      else offlineFriendList.push(friend);
    }
  } else {
    console.warn('userData.friends is undefined or not an array');
  }

  return (
    <>
      <div className="w-full bg-body-500 py-12 md:py-16">
        <Container>
          <section className="mb-12 flex flex-col md:flex-row gap-6 md:justify-between md:gap-12">
            <div className="w-full lg:max-w-[294px] lg:shrink-0">
              <CurrentUser />
              <div className="bg-body-200 rounded-xl w-full px-4 py-4 mb-4">
                <p className="uppercase text-yellow-500 text-3xl mb-6 font-black lg:w-full lg:max-w-[294px]">
                  {`${t('pages.titles.friends-request')}`}
                </p>
                <div className="py-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 max-h-96 md:max-h-[350px] flex flex-col gap-2">
                  {userData?.friendshipRequests?.length !== 0 ? (
                    <div>
                      {userData?.friendshipRequests?.map((request, index) => (
                        <FriendRequest
                          key={index}
                          request={request}
                          index={index}
                        />
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400 italic">
                      {t(
                        'pages.friends-subscreen.main-container.friendship-requests.no-friendship-requests'
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="md:flex-grow">
              <div id="header">
                <div
                  id="tabsFriends"
                  className="flex flex-wrap items-center gap-4 mb-5 md:flex-row"
                >
                  {friendView === 0 ? (
                    <Button
                      type="primary"
                      onClick={() => setFriendView(0)}
                      className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                    >
                      {t(
                        'pages.friends-subscreen.main-container.your-friends.available-status.buttons.everyone.text'
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="outline"
                      onClick={() => setFriendView(0)}
                      className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                    >
                      {t(
                        'pages.friends-subscreen.main-container.your-friends.available-status.buttons.everyone.text'
                      )}
                    </Button>
                  )}
                  {friendView === 1 ? (
                    <Button
                      type="primary"
                      onClick={() => setFriendView(1)}
                      className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                    >
                      {t(
                        'pages.friends-subscreen.main-container.your-friends.available-status.buttons.online.text'
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="outline"
                      onClick={() => setFriendView(1)}
                      className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                    >
                      {t(
                        'pages.friends-subscreen.main-container.your-friends.available-status.buttons.online.text'
                      )}
                    </Button>
                  )}
                  {friendView === 2 ? (
                    <Button
                      type="primary"
                      onClick={() => setFriendView(2)}
                      className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                    >
                      {t(
                        'pages.friends-subscreen.main-container.your-friends.available-status.buttons.offline.text'
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="outline"
                      onClick={() => setFriendView(2)}
                      className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                    >
                      {t(
                        'pages.friends-subscreen.main-container.your-friends.available-status.buttons.offline.text'
                      )}
                    </Button>
                  )}
                  <div className="md:ml-auto self-center">
                    {friendView === 3 ? (
                      <Button
                        type="outline"
                        onClick={() => setFriendView(0)}
                        className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px]"
                      >
                        {t(
                          'pages.friends-subscreen.main-container.your-friends.available-status.buttons.edit-friends.text'
                        )}
                      </Button>
                    ) : (
                      <Button
                        type="outline"
                        onClick={() => setFriendView(3)}
                        className="min-w-[100px] md:min-w-[150px] lg:min-w-[190px] bg-black border border-[white!important] text-[white!important] relative after:w-2/4 after:h-[4px] after:absolute after:bottom-[0] after:left-[-18%] after:rotate-[43deg] after:bg-[white!important] before:w-2/4 before:h-[4px] before:absolute before:top-[0] before:right-[-18%] before:rotate-[43deg] before:bg-[white!important] hover:bg-[white!important] hover:text-[black!important]"
                      >
                        {t(
                          'pages.friends-subscreen.main-container.your-friends.available-status.buttons.edit-friends.text'
                        )}
                      </Button>
                    )}
                  </div>
                </div>
                <div id="searchFriends" className="flex relative mb-8">
                  <button
                    type="primary"
                    onClick={sendNewFriendRequest}
                    className="bg-[#4B4B4B] text-white absolute top-0 left-0 h-full px-4 rounded-md uppercase text-sm font-bold hover:bg-yellow-500 hover:text-black"
                  >
                    {t(
                      'pages.friends-subscreen.main-container.make-friend.send-request-button.text'
                    )}
                  </button>
                  <input
                    type="text"
                    name="userId"
                    placeholder={'Paste unique code'}
                    value={friendRequestUserId}
                    onChange={(event) =>
                      setFriendRequestUserId(event.target.value)
                    }
                    onKeyUp={handleKeyPressed}
                    className="bg-[#636363] text-black w-full outline-none py-3 rounded-md pl-[200px]"
                  />
                </div>
              </div>

              <div id="listFriends">
                {/* All the users */}
                <div
                  className="grid grid-cols-auto-fill-minmax gap-x-10 gap-y-12"
                  style={{ '--min-col-width': '220px' }}
                >
                  {userData?.friends?.length !== 0 ? (
                    <Fragment>
                      {friendView === 0 ? (
                        <Fragment>
                          {userData?.friends?.map((element, index) => (
                            <FriendItem
                              key={index}
                              friend={element}
                              index={index}
                              removeButton={false}
                            />
                          ))}
                        </Fragment>
                      ) : null}
                      {friendView === 1 ? (
                        <Fragment>
                          {onlineFriendList.map((element, index) => (
                            <Fragment key={index}>
                              {element.online ? (
                                <FriendItem
                                  key={index}
                                  friend={element}
                                  index={index}
                                  removeButton={false}
                                />
                              ) : null}
                            </Fragment>
                          ))}
                        </Fragment>
                      ) : null}
                      {friendView === 2 ? (
                        <Fragment>
                          {offlineFriendList.map((element, index) => (
                            <Fragment key={index}>
                              {!element.online ? (
                                <FriendItem
                                  key={index}
                                  friend={element}
                                  index={index}
                                  removeButton={false}
                                />
                              ) : null}
                            </Fragment>
                          ))}
                        </Fragment>
                      ) : null}
                      {friendView === 3 ? (
                        <Fragment>
                          {userData?.friends?.map((element, index) => (
                            <FriendItem
                              key={index}
                              friend={element}
                              index={index}
                              removeButton={true}
                            />
                          ))}
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : (
                    <div className="">
                      <p className="text-gray-400 italic">
                        {t(
                          'pages.friends-subscreen.main-container.your-friends.available-status.no-friends-message'
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="w-full pb-20 lg:grid lg:grid-cols-2 lg:gap-16">
            {promotions?.map((promotion) => (
              <Card variant="medium" key={promotion.id} data={promotion} />
            ))}
          </section>
        </Container>
        <FeaturedVideos data={featuredVideos} />
      </div>
      <br />
      <FriendsSubscreen />
    </>
  );
}
