import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';

export default function SignupToGetSkin(props) {
  const { data } = props;

  return (
    <div className="w-full relative min-h-[269px] text-center flex justify-center items-center overflow-hidden">
      <div className="relative z-20">
        <h4 className="text-white uppercase font-black text-2xl mb-10 | xl:text-5xl">
          {data.title}
        </h4>
        <div
          onClick={() => {
            window.location = process.env.REACT_APP_STEAM_LOGIN_URL;
          }}
        >
          <Button
            key={data.cta.id}
            variant={data.cta.variant}
            type={data.cta.type}
          >
            {data.cta.name}
          </Button>
        </div>
      </div>
      <Image src={data.image.src} type="cover" alt={data.title} />
    </div>
  );
}
