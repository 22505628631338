import Image from 'components/atoms/Image';

export default function Hero(props) {
  const { data } = props;
  return (
    <div className="w-full h-[200px] flex items-end justify-center relative overflow-hidden | md:h-[400px]">
      <h1
        className="text-base inline-block clip-path-button px-5 py-3 font-[900] uppercase relative z-10 mb-5 | lg:text-5xl lg:px-9 lg:py-4 bg-yellow-500 text-black"
        style={{
          clipPath: 'polygon(94% 0, 100% 30%, 100% 100%, 6% 100%, 0 75%, 0 0)',
        }}
      >
        {data.title}
      </h1>
      <Image src={data.image.src} type="cover" />
    </div>
  );
}
