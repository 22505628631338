import Container from 'components/atoms/Container';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';

export default function BannerLogin(props) {
  const { data } = props;

  return (
    <div className="w-full relative overflow-hidden py-7">
      <Container className="flex flex-col md:h-[319px] md:flex-row relative z-10">
        <article className="flex items-center py-8 mx-auto relative z-10">
          <div className="bg-body-300 text-black rounded-[20px] relative py-7 px-8 max-w-[1048px] | md:rounded-[30px] lg:py-10 lg:px-10">
            <div className="flex items-center relative z-10">
              <div className="w-[80px] | lg:w-[135px]">{data.imgLogo}</div>
              <div className="bg-black h-[80px] w-[7px] mx-6 | md:h-[117px]"></div>
              <h2 className="flex flex-col leading-5 | text-xl font-[900] | lg:text-[2rem] lg:leading-8 | xl:text-[2.8rem] xl:leading-10">
                <span>{data.paragraph}</span>
                <span>{data.subParagraph}</span>
              </h2>
            </div>
            <Button
              to={data.cta.url}
              variant={data.cta.variant}
              type={data.cta.type}
              className="sm:text-white z-10 absolute -bottom-6 right-0 | md:-bottom-8 md:right-8"
            >
              {data.cta.name}
            </Button>
            <div className="overflow-hidden absolute top-0 right-0 left-0 w-full h-full rounded-[20px] | md:rounded-[30px]">
              <Image src={data.imgPattern.src} type="cover" />
            </div>
          </div>
        </article>
      </Container>
      {data?.imgBg && <Image src={data.imgBg.src} type="cover" />}
    </div>
  );
}
