import { createSlice } from '@reduxjs/toolkit';

export const matchRequestSlice = createSlice({
  name: 'matchRequest',
  initialState: {
    matchRequest: true,
  },
  reducers: {
    startMatchRequest(state, action) {
      state.matchRequest = false;
    },
    stopMatchRequest(state, action) {
      state.matchRequest = true;
    },
  },
});

export const { startMatchRequest, stopMatchRequest } =
  matchRequestSlice.actions;

export default matchRequestSlice.reducer;
