import { useState, useEffect } from 'react';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FriendItem, FriendRequest } from 'components';

import { makeNewFriendshipRequest } from 'app/socketConnection';

const FriendsSubscreen = (props) => {
  const { userData } = useSelector((state) => state.user); // pick the user from the redux store

  // value of the input field of new friend
  const [friendRequestUserId, setFriendRequestUserId] = useState('');

  const { t } = useTranslation('global');

  const onlineFriendList = [];
  const offlineFriendList = [];

  if (userData && Array.isArray(userData.friends)) {
    for (const friend of userData.friends) {
      if (friend.online) onlineFriendList.push(friend);
      else offlineFriendList.push(friend);
    }
  } else {
    console.warn('userData.friends is undefined or not an array');
  }

  const sendNewFriendRequest = () => {
    makeNewFriendshipRequest(friendRequestUserId);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    ReactGa.pageview(pathname); // set what page is being seen
  }, [pathname]);

  /* Variable to see what list of friends should load
        0 -> everyone
        1 -> online
        2 -> offline
        3 -> removeButton friend
    */
  const [friendView, setFriendView] = useState(0);

  return (
    <div className="hidden">
      {/* friends container */}
      <div className="bg-brown-800 px-6 py-4">
        {/* New friend request */}
        <div className="mb-5">
          {/* New friend text */}
          <p className="text-gray-200 text-4xl">
            {t('pages.friends-subscreen.main-container.make-friend.title')}
          </p>
          {/* Text input and button container */}
          <div className="flex flex-wrap items-center">
            {/* # icon and text input container */}
            <div className="flex items-center border-b border-white w-fit mr-3">
              <p className="text-gray-400 text-2xl">#</p>
              <input
                type="text"
                name="userId"
                placeholder={'12345'}
                value={friendRequestUserId}
                onChange={(event) => setFriendRequestUserId(event.target.value)}
                className="bg-brown-800 text-gray-400 mr-5 px-2 py-1 w-82 outline-none"
              />
            </div>
            {/* button of send request */}
            <button
              onClick={sendNewFriendRequest}
              className="bg-green-800 hover:bg-green-900 my-2 py-2 px-4 rounded-md"
            >
              <p className="text-white">
                {t(
                  'pages.friends-subscreen.main-container.make-friend.send-request-button.text'
                )}
              </p>
            </button>
          </div>
        </div>
        {/* Friends title */}
        <p className="text-gray-200 text-4xl mt-3">
          {t('pages.friends-subscreen.main-container.your-friends.title')}
        </p>
        {/* Button line */}
        <div className="flex justify-between align-middle">
          <div className="space-x-3 ">
            {friendView === 0 ? (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-yellow-400 border-yellow-400"
                onClick={() => {
                  setFriendView(0);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.everyone.text'
                )}
              </button>
            ) : (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-white hover:text-yellow-600 hover:border-yellow-600"
                onClick={() => {
                  setFriendView(0);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.everyone.text'
                )}
              </button>
            )}
            {friendView === 1 ? (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-yellow-400 border-yellow-400"
                onClick={() => {
                  setFriendView(1);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.online.text'
                )}
              </button>
            ) : (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-white hover:text-yellow-600 hover:border-yellow-600"
                onClick={() => {
                  setFriendView(1);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.online.text'
                )}
              </button>
            )}
            {friendView === 2 ? (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-yellow-400 border-yellow-400"
                onClick={() => {
                  setFriendView(2);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.offline.text'
                )}
              </button>
            ) : (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-white hover:text-yellow-600 hover:border-yellow-600"
                onClick={() => {
                  setFriendView(2);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.offline.text'
                )}
              </button>
            )}
          </div>
          <div className="">
            {friendView === 3 ? (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-red-600 border-red-600"
                onClick={() => {
                  setFriendView(0);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.edit-friends.text'
                )}
              </button>
            ) : (
              <button
                className="border-solid my-4 py-3 px-4 border-2 rounded-sm text-white hover:text-red-600 hover:border-red-600"
                onClick={() => {
                  setFriendView(3);
                }}
              >
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.buttons.edit-friends.text'
                )}
              </button>
            )}
          </div>
        </div>
        {/* All the users */}
        <div className="py-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 max-h-96 md:max-h-[350px]">
          {userData?.friends?.length !== 0 ? (
            <div>
              {friendView === 0 ? (
                <div>
                  {userData?.friends?.map((element, index) => (
                    <FriendItem
                      key={index}
                      friend={element}
                      index={index}
                      removeButton={false}
                    />
                  ))}
                </div>
              ) : null}
              {friendView === 1 ? (
                <div>
                  {onlineFriendList.map((element, index) => (
                    <div key={index}>
                      {element.online ? (
                        <FriendItem
                          key={index}
                          friend={element}
                          index={index}
                          removeButton={false}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
              {friendView === 2 ? (
                <div>
                  {offlineFriendList.map((element, index) => (
                    <div key={index}>
                      {!element.online ? (
                        <FriendItem
                          key={index}
                          friend={element}
                          index={index}
                          removeButton={false}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
              {friendView === 3 ? (
                <div>
                  {userData?.friends?.map((element, index) => (
                    <FriendItem
                      key={index}
                      friend={element}
                      index={index}
                      removeButton={true}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="">
              <p className="text-gray-400 italic">
                {t(
                  'pages.friends-subscreen.main-container.your-friends.available-status.no-friends-message'
                )}
              </p>
            </div>
          )}
        </div>
      </div>
      {/* friend requests section */}
      <div className="bg-brown-800 px-6 py-4 mb-4">
        {/* Top title */}
        <p className="text-gray-200 text-4xl mb-3">
          {t(
            'pages.friends-subscreen.main-container.friendship-requests.title'
          )}
        </p>
        <div className="py-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 max-h-96 md:max-h-[350px]">
          {userData?.friendshipRequests?.length !== 0 ? (
            <>
              {userData?.friendshipRequests?.map((request, index) => (
                <FriendRequest key={index} request={request} index={index} />
              ))}
            </>
          ) : (
            <div className="">
              <p className="text-gray-400 italic">
                {t(
                  'pages.friends-subscreen.main-container.friendship-requests.no-friendship-requests'
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FriendsSubscreen;
