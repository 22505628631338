import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';
import Logout from 'components/atoms/icons/Logout';

export default function User(props) {
  const { data, className, logout, linkUser } = props;

  return (
    <div className={`absolute top-0 right-0 w-full h-full ${className || ''}`}>
      <div className="relative border-yellow-500 border-2 rounded-full w-full h-full overflow-hidden">
        <Button
          to={linkUser ? '/user/profile' : ''}
          variant={linkUser ? 'link' : 'button'}
          className="w-full h-full py-[0!important] px-[0!important]"
        >
          <Image type="cover" src={data?.avatar} />
        </Button>
      </div>
      {logout && (
        <Button
          onClick={() => (window.location = process.env.REACT_APP_LOGOUT_URL)}
          className="absolute -right-2 -bottom-8 py-[0!important] px-[0!important] | -md:right-6 md:py-[0!important] md:px-[0!important] | lg:py-[0!important] lg:px-[0!important]"
        >
          <Logout className="scale-75" />
        </Button>
      )}
    </div>
  );
}
