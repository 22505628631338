import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FriendItem from 'components/molecules/FriendItem';

export default function FriendList() {
  const { t } = useTranslation('global');
  const { userData } = useSelector((state) => state.user);

  const [divHeight, setDivHeight] = useState(0);

  const updateDivHeight = (height) => {
    setDivHeight(height);
  };

  const title = t('pages.home.left-column.title');

  const nodes = userData?.friends?.map((friend) => ({
    idPublic: friend.idPublic,
    online: friend.online,
    userAvatar: friend.userAvatar,
    userName: friend.userName,
    userTeam: friend.idPublic,
    cta: {
      name: `${t('components.friend-item.invite')}`,
      type: 'primary',
      variant: 'link',
      url: '/user/play',
    },
  }));

  const data = {
    title,
    nodes,
  };

  return (
    <div id="friends" className="w-full">
      {userData?.friends?.length > 0 && (
        <>
          <h3 className="uppercase text-yellow-500 text-3xl mb-6 font-black">
            {data.title}
          </h3>
          <div
            className="friends-list xl:grid xl:grid-cols-2 xl:gap-4 pr-4"
            style={{ maxHeight: `${divHeight * 4 + 64}px`, overflow: 'auto' }}
          >
            {data?.nodes?.map((item) => (
              <FriendItem
                key={item.idPublic}
                data={item}
                updateHeight={updateDivHeight}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
