import { useTranslation } from 'react-i18next';
import imgPrincipal from 'assets/images/bg_principal.jpg';
import imgExclusiveSkinsPrincipal from 'assets/images/exclusive_skins.jpg';
import imgExclusiveSkinsObj from 'assets/images/exclusive_skins_obj.png';
import imgPlayVsFriends from 'assets/images/play_vs_friends.jpg';
import imgPlayVsFriendsObj from 'assets/images/play_vs_friends_obj.png';
import imgSteamBg from 'assets/images/bg_steam.jpg';
import imgSteamPattern from 'assets/images/bg_steam_pattern.jpg';
import Steam from 'components/atoms/icons/Steam';
import bgStore from 'assets/images/bg_store_02.jpg';
import bgContact from 'assets/images/bg_contact.jpg';
import bgPattern from 'assets/images/bg.jpg';
import bgBannerRegister from 'assets/images/banner-register.png';
import bgRegister from 'assets/images/bg_register.png';
import imageSoldier from 'assets/images/soldier.png';

export default function useBanner() {
  const { t } = useTranslation('global');

  const principalBanner = {
    id: 0,
    minitag: `${t('pages.landing.top-image.middle-part.first')}`,
    title: `${t('pages.landing.top-image.middle-part.second')}`,
    subtitle: `${t('pages.landing.top-image.middle-part.third')}`,
    paragraph: `${t('pages.landing.top-image.middle-part.fourth')}`,
    cta: [
      {
        id: 0.1,
        name: `${t('pages.landing.top-image.buttons.play')}`,
        variant: 'outside-link',
        type: 'primary',
        url: process.env.REACT_APP_STEAM_LOGIN_URL,
      },
      {
        id: 0.2,
        name: `${t('pages.landing.top-image.buttons.store')}`,
        variant: 'link',
        type: 'secondary',
        url: '/store',
      },
    ],
    imagePrimary: {
      src: imgPrincipal,
      alt: '',
    },
    imageSecondary: {
      src: null,
      alt: '',
    },
    imagePattern: {
      src: bgPattern,
      alt: '',
    },
  };

  const exclusiveSkins = {
    id: 1,
    title: `${t('pages.landing.exclusive-skins.first.title')}`,
    paragraph: `${t('pages.landing.exclusive-skins.first.body')}`,
    imagePrimary: {
      src: imgExclusiveSkinsPrincipal,
      alt: '',
    },
    imageSecondary: {
      src: imgExclusiveSkinsObj,
      alt: '',
    },
    cta: {
      id: 0.1,
      name: `${t('pages.ctas.visit-store')}`,
      variant: 'link',
      type: 'primary',
      url: '/store',
    },
  };

  const playVsFriends = {
    id: 2,
    title: `${t('pages.landing.exclusive-skins.second.title')}`,
    paragraph: `${t('pages.landing.exclusive-skins.second.body')}`,
    imagePrimary: {
      src: imgPlayVsFriends,
      alt: '',
    },
    imageSecondary: {
      src: imgPlayVsFriendsObj,
      alt: '',
    },
    cta: {
      id: 0.1,
      name: 'Compete',
      variant: 'outside-link',
      type: 'primary',
      url: process.env.REACT_APP_STEAM_LOGIN_URL,
    },
  };

  const signInWithSteam = {
    id: 3,
    paragraph: `${t('pages.landing.advice.first')}`,
    subParagraph: `${t('pages.landing.advice.second')}`,
    imgPattern: {
      src: imgSteamPattern,
      alt: '',
    },
    imgBg: {
      src: imgSteamBg,
      alt: '',
    },
    imgLogo: <Steam />,
    cta: {
      id: 0.1,
      name: `${t('pages.landing.advice.cta')}`,
      variant: 'outside-link',
      type: 'secondary',
      url: process.env.REACT_APP_STEAM_LOGIN_URL,
    },
  };

  const store = {
    id: 4,
    title: `${t('pages.store.title')}`,
    image: {
      src: bgStore,
      alt: '',
    },
  };

  const contact = {
    id: 5,
    title: `${t('pages.titles.contact')}`,
    image: {
      src: bgContact,
      alt: '',
    },
  };

  const terms = {
    id: 6,
    title: `${t('pages.terms.title')}`,
    image: {
      src: bgStore,
      alt: '',
    },
  };

  const cookies = {
    id: 7,
    title: `${t('pages.cookies.title')}`,
    image: {
      src: bgStore,
      alt: '',
    },
  };

  const privacy = {
    id: 8,
    title: `${t('pages.privacy.title')}`,
    image: {
      src: bgStore,
      alt: '',
    },
  };

  const register = {
    id: 9,
    title: `${t('pages.register.title')}`,
    image: {
      src: bgBannerRegister,
      alt: '',
    },
    imageBackground: {
      src: bgRegister,
      alt: '',
    },
    imagePrimary: {
      src: imageSoldier,
      alt: '',
    },
  };

  return {
    principalBanner,
    exclusiveSkins,
    playVsFriends,
    signInWithSteam,
    store,
    contact,
    terms,
    cookies,
    privacy,
    register,
  };
}
