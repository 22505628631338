import Container from 'components/atoms/Container';
import useHome from 'hooks/useHome';
import Friend from 'components/molecules/FriendItem';
import LastGame from 'components/molecules/LastGameItem';
import Card from 'components/molecules/Card';

export default function Profile() {
  const { friends, lastGames, promotions } = useHome();

  return (
    <div className="w-full bg-body-500">
      <Container>
        <section className="flex flex-col gap-8 mb-12 | lg:grid lg:grid-cols-2 lg:gap-24 lg:gap-y-12">
          <div id="friends" className="w-full">
            <h3 className="uppercase text-yellow-500 text-3xl mb-6 font-black">
              {friends.title}
            </h3>
            <div className="lg:grid lg:grid-cols-2 lg:gap-4">
              {friends?.nodes?.map((item) => (
                <Friend key={item.idPublic} data={item} />
              ))}
            </div>
          </div>

          <div id="lastGames" className="w-full mb-12">
            <h3 className="uppercase text-yellow-500 text-3xl mb-6 font-black">
              {lastGames.title}
            </h3>
            <div className="w-full mx-auto lg:w-10/12">
              {lastGames?.nodes?.map((item) => (
                <LastGame key={item.id} data={item} />
              ))}
            </div>
          </div>
        </section>

        <section className="w-full pb-20 lg:grid lg:grid-cols-2 lg:gap-16">
          {promotions?.map((promotion) => (
            <Card variant="medium" key={promotion.id} data={promotion} />
          ))}
        </section>
      </Container>
    </div>
  );
}
