import Container from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { updateTradelinkSocket } from 'app/socketConnection';

export default function BannerPromotional(props) {
  const { data } = props;
  const { t } = useTranslation('global');

  const [tradelinkInput, setTradelinkInput] = useState('');

  const updateTradelinkButtonPressed = () => {
    updateTradelinkSocket(tradelinkInput);
    setTradelinkInput('');
  };

  const handleKeyPressed = (e) => {
    if (e.key === 'Enter') updateTradelinkButtonPressed();
  };

  return (
    <div className="w-full relative overflow-hidden py-7">
      <Container className="flex flex-col md:h-[319px] md:flex-row relative z-10">
        <article className="flex items-center py-8 mx-auto relative z-10">
          <div className="bg-body-300 text-black rounded-[20px] relative py-7 px-8 max-w-[1048px] | md:rounded-[30px] lg:py-10 lg:px-10">
            <div className="flex items-center relative z-10">
              <div className="w-[80px] | lg:w-[135px]">{data.imgLogo}</div>
              <div className="bg-black h-[80px] w-[7px] mx-6 | md:h-[117px]"></div>
              <h2 className="flex flex-col leading-5 | text-xl font-[900] | lg:text-[2rem] lg:leading-8 | xl:text-[2.8rem] xl:leading-10">
                <span>
                  {t(
                    'pages.profile-subscreen.top-section.update-tradelink.text'
                  )}
                </span>
              </h2>
            </div>
            <div
              id="searchFriends"
              className="flex justify-start relative z-20 rounded-sm mt-10"
            >
              <button
                type="primary"
                className="bg-[#4B4B4B] text-white absolute top-0 left-0 h-full px-4 rounded-md uppercase text-sm font-bold duration-200 hover:duration-200 hover:bg-black hover:text-yellow-500"
                onClick={updateTradelinkButtonPressed}
              >
                {t(
                  'pages.profile-subscreen.top-section.update-tradelink.save-tradelink-button.text'
                )}
              </button>
              <input
                type="text"
                name="userId"
                placeholder={'Paste you new tradelink'}
                onChange={(e) => setTradelinkInput(e.target.value)}
                value={tradelinkInput}
                onKeyUp={handleKeyPressed}
                className="bg-[#636363] text-black w-full outline-none py-3 rounded-md pl-[120px]"
              />
            </div>
            <div className="flex justify-end relative z-50">
              <a
                href="https://steamcommunity.com/profiles/76561199046468718/tradeoffers/privacy#trade_offer_access_url"
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center underline text-black font-bold mt-2 duration-200 hover:duration-200 ml-auto text-xl"
              >
                {t(
                  'pages.profile-subscreen.top-section.update-tradelink.where-to-get-button.text'
                )}
              </a>
            </div>
            <div className="overflow-hidden absolute top-0 right-0 left-0 w-full h-full rounded-[20px] | md:rounded-[30px]">
              <Image src={data.imgPattern.src} type="cover" />
            </div>
          </div>
        </article>
      </Container>
      {data?.imgBg && <Image src={data.imgBg.src} type="cover" />}
    </div>
  );
}
