import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isUserLoggedIn: localStorage.getItem('userLoggedIn') === 'true',
    isSocketConnected: false,
    userData: [],
    // require("../../data/example/user"),
    matchRequests: [],
    // require("../../data/example/matchRequests.json"),
    currentMatch: {
      currentlyPlaying: false,
    },
    finishedMatch: {
      finishedPlaying: true,
    },
    errorsMatchRequest: [],
    // currentMatch: require('../../data/example/currentMatch.json'),
  },
  reducers: {
    setIsUserLoggedIn: (state, action) => {
      // Sets the global state of user logged in
      state.isUserLoggedIn = action.payload;
    },
    setSocketConnected: (state, action) => {
      // Sets if the user has been to the login route and hash the passport auth
      state.isSocketConnected = action.payload;
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    updateTradelink: (state, action) => {
      // Updates the user's tradelink value
      state.userData.tradelink = action.payload;
    },
    addFriendshipRequest: (state, action) => {
      // Adds a friend request

      const request = {
        id: action.payload.friendshipRequestId,
        user: {
          userAvatar: action.payload.user.userAvatar,
          userName: action.payload.user.userName,
          online: action.payload.user.online,
        },
      };
      state.userData.friendshipRequests.push(request);
    },
    removeFriendshipRequest: (state, action) => {
      // Rejects a frienship request

      // search the request
      let index = 0;
      while (index < state.userData.friendshipRequests.length) {
        if (state.userData.friendshipRequests[index].id === action.payload) {
          state.userData.friendshipRequests.splice(index, 1);
          break;
        }
        index++;
      }
    },
    addFriend: (state, action) => {
      const friend = {
        idPublic: action.payload.idPublic,
        userAvatar: action.payload.userAvatar,
        userName: action.payload.userName,
        online: action.payload.online,
      };
      state.userData?.friends?.push(friend);
    },
    removeFriend: (state, action) => {
      // Deletes a frienship relationship

      // search the user
      let index = 0;
      while (index < state.userData?.friends?.length) {
        if (state.userData.friends[index].idPublic === action.payload) {
          state.userData?.friends?.splice(index, 1); // remove the friend if matches
          break;
        }
        index++;
      }
    },
    addMatchRequest: (state, action) => {
      // Check if the match is in matchRequests.
      for (const match of state.matchRequests) {
        if (match.matchId === action.payload.matchId) return;
      }
      state.matchRequests.push(action.payload);
    },
    addMatchRequestError: (state, action) => {
      state.errorsMatchRequest.push(action.payload);
    },
    removeMatchRequestError: (state, action) => {
      state.errorsMatchRequest.pop(action.payload);
    },
    removeMatchRequest: (state, action) => {
      const matchId = action.payload.matchPublicId || action.payload;
      state.matchRequests = state.matchRequests.filter(
        (match) => match.matchId !== matchId
      );
    },
    friendConnected: (state, action) => {
      for (const friend of state.userData.friends) {
        if (friend.idPublic === action.payload.friendPublicId) {
          friend.online = true;
          break;
        }
      }
    },
    friendDisconnected: (state, action) => {
      for (const friend of state.userData.friends) {
        if (friend.idPublic === action.payload.friendPublicId) {
          friend.online = false;
          break;
        }
      }
    },
    startGame: (state, action) => {
      state.userData.currentMatch = {
        currentlyPlaying: true,
        match: {
          oponent1: {
            name: state.userData.name,
            idPublic: state.userData.idPublic,
            avatar: state.userData.avatar,
            win: state.userData.win,
            kd: state.userData.kd,
            waggies: action.payload.betQuantity,
          },
          oponent2: {
            name: action.payload.friendData.userName,
            idPublic: action.payload.friendData.idPublic,
            avatar: action.payload.friendData.userAvatar,
            win: action.payload.friendData.win,
            kd: action.payload.friendData.kd,
            waggies: action.payload.betQuantity,
          },
          serverIp: action.payload.serverPublicIp,
        },
      };
    },
    endGame: (state, action) => {
      state.userData.currentMatch = {
        currentlyPlaying: false,
      };

      state.userData.finishedMatch = {
        finishedPlaying: true,
        match: {
          oponent1: {
            name: action.payload.senderData.userName,
            idPublic: action.payload.senderData.idPublic,
            avatar: action.payload.senderData.userAvatar,
            win: action.payload.senderData.win,
            kd: action.payload.senderData.kd,
            killsInMatch: action.payload.senderData.killsInMatch,
          },
          oponent2: {
            name: action.payload.receiverData.userName,
            idPublic: action.payload.receiverData.idPublic,
            avatar: action.payload.receiverData.userAvatar,
            win: action.payload.receiverData.win,
            kd: action.payload.receiverData.kd,
            killsInMatch: action.payload.receiverData.killsInMatch,
          },
          senderWins: action.payload.senderWins,
          wagiesBet: action.payload.betQuantity,
          matchDuration: action.payload.matchDuration,
        },
      };
    },
    closeGame: (state, action) => {
      state.userData.finishedMatch = {
        finishedPlaying: false,
      };
    },
  },
});

export const {
  setIsUserLoggedIn,
  setSocketConnected,
  setUser,
  updateTradelink,
  addFriendshipRequest,
  removeFriendshipRequest,
  addFriend,
  removeFriend,
  addMatchRequest,
  addMatchRequestError,
  removeMatchRequestError,
  startGame,
  startGameError,
  endGame,
  closeGame,
  removeMatchRequest,
  updateSocketConnectionStatus,
  friendConnected,
  friendDisconnected,
} = userSlice.actions;

export default userSlice.reducer;
