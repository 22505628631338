import { useRef, useState, useEffect } from 'react';
import genericImage from 'assets/images/generic.jpg';
import LastGameBg from 'components/atoms/LastGameBg';
import Image from 'components/atoms/Image';
import wagenCoin from 'assets/images/wagencoin.svg';

export default function LastGameItem(props) {
  const { data, updateHeight } = props;

  const [divHeight, setDivHeight] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const height = divRef.current.clientHeight;
        setDivHeight(height);
        updateHeight(height);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateHeight]);

  return (
    <article className="relative" ref={divRef}>
      <div className="flex justify-between h-full">
        <div className="absolute flex items-center gap-3 h-4/5 top-[4%] left-[14%]">
          <Image
            src={data?.friend?.userAvatar || genericImage}
            alt={data?.friend?.userName}
            className="h-14 lg:h-20 rounded-full"
          />
          <div className="text-white">
            <h2 className="flex mb-2 items-center">
              <b className="mr-2 break-all uppercase flex-inline max-w-48 text-[13px] leading-tight">
                {data?.friend?.userName}
              </b>
              {data?.userWins ? (
                <b className="text-green-500 text-[14px]">{`${data?.betAmount}`}</b>
              ) : (
                <b className="text-red-500  text-[14px]">{`-${data?.betAmount}`}</b>
              )}
              <Image src={wagenCoin} width="18px" className="ml-1" />
            </h2>
            <div className="text-[13px]">
              <p>Kills: {data?.userKills}</p>
              <p>Deads: {data?.friendKills}</p>
            </div>
          </div>
        </div>
        <div
          type="primary"
          className={`clip-path-button absolute top-[25%] right-[12%] h-7 w-12 px-0 py-0 lg:top-[35%] flex items-center justify-center font-black
          ${
            data?.userWins === true &&
            'bg-[#4ADE80] sm:bg-[#4ADE80] sm:hover:bg-[#4ADE80]'
          }
          ${
            data?.userWins === false &&
            'bg-[#EF4444] sm:bg-[#EF4444] sm:hover:bg-[#EF4444]'
          }`}
        >
          {data?.userWins ? 'W' : 'L'}
        </div>
      </div>
      <LastGameBg />
    </article>
  );
}
