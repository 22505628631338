import { useTranslation } from 'react-i18next';
import { removeFrienship } from 'app/socketConnection';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';
import Trash from 'components/atoms/icons/Trash';
import { useDispatch } from 'react-redux';
import { setSelectedFriend } from 'features/friend/selectedFriendSlice';

const FriendItem = (props) => {
  const { t } = useTranslation('global');
  const removeFriendHelper = () => removeFrienship(props.friend.idPublic);

  const dispatch = useDispatch();

  const handleSelectFriend = (friend) => {
    dispatch(setSelectedFriend(friend));
  };

  return (
    <>
      {/* index variable is used in order to set the background dark grey or light grey */}
      {props.index % 2 === 0 ? (
        <div className="bg-black">
          <div className="bg-brown-700 flex flex-col flex-shrink-0 items-center justify-center relative w-full">
            <div className="w-full relative overflow-hidden flex items-center justify-center py-8">
              <div className="relative overflow-hidden rounded-full w-36 h-36 flex items-center">
                <Image
                  src={props.friend.userAvatar}
                  alt="friendAvatar"
                  type="cover"
                />
              </div>
            </div>
            <div className="bg-black text-white break-all py-4 px-4 w-full uppercase flex flex-wrap mt-auto">
              <div>
                <p className="font-bold">{props.friend.userName}</p>
                <small>{props.friend.idPublic}</small>
              </div>
              {props.removeButton ? (
                <button
                  className="bg-red-500 hover:bg-red-700 py-2 px-4 text-white flex items-center gap-1 ml-auto mr-0"
                  onClick={removeFriendHelper}
                >
                  {t('pages.ctas.trash')} <Trash />
                </button>
              ) : (
                <>
                  {props.friend.online ? (
                    <Button
                      variant="link"
                      to="/user/play"
                      onClick={() => handleSelectFriend(props.friend)}
                      type="primary"
                      className="py-[0!important] px-[0!important] my-auto ml-auto mr-0"
                    >
                      <span className="block text-sm py-1 px-3">
                        {t('components.friend-item.invite')}
                      </span>
                    </Button>
                  ) : null}
                </>
              )}
            </div>
            {props.friend.online ? (
              <div className="absolute top-2 right-2 w-[15px] h-[15px] min-w-[15px] rounded-full bg-green-500"></div>
            ) : (
              <div className="absolute top-2 right-2 w-[15px] h-[15px] min-w-[15px] rounded-full bg-red-400"></div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="bg-brown-700 flex flex-col flex-shrink-0 items-center justify-center relative w-full">
            <div className="w-full relative overflow-hidden flex items-center justify-center py-8">
              <div className="relative overflow-hidden rounded-full w-36 h-36 flex items-center">
                <Image
                  src={props.friend.userAvatar}
                  alt="friendAvatar"
                  type="cover"
                />
              </div>
            </div>
            <div className="bg-black text-white break-all py-4 px-4 w-full uppercase flex flex-wrap mt-auto">
              <div>
                <p className="font-bold">{props.friend.userName}</p>
                <small>{props.friend.idPublic}</small>
              </div>
              {props.removeButton ? (
                <button
                  className="bg-red-500 hover:bg-red-700 py-2 px-4 text-white flex items-center gap-1 ml-auto mr-0"
                  onClick={removeFriendHelper}
                >
                  {t('pages.ctas.trash')} <Trash />
                </button>
              ) : (
                props.friend.online && (
                  <Button
                    variant="link"
                    to="/user/play"
                    onClick={() => handleSelectFriend(props.friend)}
                    type="primary"
                    className="py-[0!important] px-[0!important] my-auto ml-auto mr-0"
                  >
                    <span className="block text-sm py-1 px-3">
                      {t('components.friend-item.invite')}
                    </span>
                  </Button>
                )
              )}
            </div>
            {props.friend.online ? (
              <div className="absolute top-2 right-2 w-[15px] h-[15px] min-w-[15px] rounded-full bg-green-500"></div>
            ) : (
              <div className="absolute top-2 right-2 w-[15px] h-[15px] min-w-[15px] rounded-full bg-red-400"></div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FriendItem;
