import { Helmet } from 'react-helmet-async';
import wagen from '../../assets/images/wagen_games.png';

export default function Meta({
  title = 'Counter strike competitive platform',
  description = '¡PLAY TO WIN! | Play Counter Strike matches and get free skins. Compete like a professional against other players in 1vs1 matches and get amazing prices. | CS2 (CS:GO) WAGGERS OFFICIAL PLATFORM.',
  keywords = 'csgo, counter strike, play counter, play counter with friends, csgo 1vs1, private match csgo, weapons csgo, buy weapons csgo, best webpage to play csgo.',
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://csgo.wagen.games/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={wagen} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:domain" content="csgo.wagen.games" />
      <meta name="twitter:url" content="https://csgo.wagen.games/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={wagen} />
    </Helmet>
  );
}
