import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import waggieCoin from 'assets/images/waggieCoin.png';
import { deleteMatchRequest, acceptMatchRequest } from 'app/socketConnection';

const MatchRequestItem = (props) => {
  const { t } = useTranslation('global');
  const { match } = props;
  const { userData } = useSelector((state) => state.user);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleAction = async (action) => {
    setIsButtonDisabled(true);
    if (action === 'accept') {
      await acceptMatchRequest(match?.matchId);
    } else {
      await deleteMatchRequest(match?.matchId);
    }
  };

  if (
    userData?.idPublic === match.matchSenderUser?.idPublic ||
    (userData?.idPublic !== match.matchReceiverUser?.idPublic &&
      match.matchReceiverUser?.idPublic)
  ) {
    return (
      // current user sent the request
      <div className="flex gap-2 relative bg-brown-500 flex-wrap p-2">
        <div className="flex justify-around items-center gap-1">
          <img
            src={match.matchReceiverUser?.avatar}
            alt="userAvatar"
            className="align-middle rounded-sm h-10 w-10"
          />
          <p className="text-gray-100 text-l text-center flex-shrink-0 basis-3/4">
            {match.matchReceiverUser?.name}
          </p>
        </div>
        <div className="flex items-center ml-auto gap-2 flex-grow justify-end">
          <div className="flex items-center">
            <p className="text-gray font-quantum text-yellow-700 opacity-90">
              {t('components.match-request.match-types.custom')}
            </p>
            <p className="flex-shrink-0 mx-1 font-quantum text-yellow-800 opacity-90">
              {match.matchData.ammountBet}
            </p>
            <img
              src={waggieCoin}
              alt="wagie-coin"
              className="h-6 flex-shrink-0"
            />
          </div>
          <div className=" flex items-center gap-2">
            <button
              className="bg-red-500 hover:bg-red-700 py-2 px-2"
              onClick={() => handleAction('cancel')}
              disabled={isButtonDisabled}
            >
              <p className="text-white">{t('pages.ctas.cancel')}</p>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    // current user received the request
    return (
      <div className="flex gap-2 relative bg-brown-500 flex-wrap p-2">
        <div className="flex justify-around items-center gap-1">
          <img
            src={match.matchSenderUser?.avatar}
            alt="userAvatar"
            className="align-middle rounded-sm h-10 w-10"
          />
          <p className="text-gray-100 text-l text-center flex-shrink-0 basis-3/4">
            {match.matchSenderUser?.name}
          </p>
        </div>
        <div className="flex items-center ml-auto gap-2 flex-grow justify-end">
          <div className="flex items-center">
            <p className="text-gray font-quantum text-yellow-700 opacity-90">
              {t('components.match-request.match-types.custom')}
            </p>
            <p className="flex-shrink-0 mx-1 font-quantum text-yellow-800 opacity-90">
              {match.matchData.ammountBet}
            </p>
            <img
              src={waggieCoin}
              alt="wagie-coin"
              className="h-6 flex-shrink-0"
            />
          </div>
          <div className=" flex items-center gap-2">
            <button
              className="bg-green-600 hover:bg-green-700 py-2 px-2"
              onClick={() => handleAction('accept')}
              disabled={isButtonDisabled}
            >
              <p className="text-white">
                {t('components.match-request.buttons.accept-match')}
              </p>
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 py-2 px-4"
              onClick={() => handleAction('reject')}
              disabled={isButtonDisabled}
            >
              <p className="text-white">
                {t('components.match-request.buttons.decline-match')}
              </p>
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default MatchRequestItem;
