import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlayToWinSection from 'components/organisms/landing/PlayToWinSection';
import FeaturedWeapons from 'components/organisms/landing/FeaturedWeapons';
import FeaturedVideos from 'components/organisms/landing/FeaturedVideos';
import SignupToGetSkin from 'components/organisms/landing/SignupToGetSkin';
import Banner from 'components/templates/Banner';
import useBanner from 'hooks/useBanner';
import useSection from 'hooks/useSection';
import Meta from 'components/atoms/Meta';

export default function Landing() {
  const { principalBanner, exclusiveSkins, playVsFriends, signInWithSteam } =
    useBanner();

  const { playToWin, featuredWeapons, featuredVideos, signupToGetSkin } =
    useSection();

  const { isUserLoggedIn } = useSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/user/home');
    }
  }, [isUserLoggedIn, navigate]);

  return (
    <>
      <Meta />
      <Banner variant="principal" data={principalBanner} />
      <PlayToWinSection data={playToWin} />
      <Banner variant="middle" orientation="left" data={exclusiveSkins} />
      <Banner variant="middle" orientation="right" data={playVsFriends} />
      <Banner variant="logginWithSteam" data={signInWithSteam} />
      <FeaturedWeapons data={featuredWeapons} />
      <FeaturedVideos data={featuredVideos} />
      <SignupToGetSkin data={signupToGetSkin} />
    </>
  );
}
