export default function useStats() {
  const apexChartOptions = {
    chart: {
      type: 'area',
      height: '100%',
      zoom: {
        enabled: false,
      },
      foreColor: '#8f8f8f',
      toolbar: {
        show: false,
      },
    },
    colors: ['#fbe417'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: 'left',
    },
  };

  const optionsKD = { ...apexChartOptions };

  optionsKD.title = {
    text: 'K / D',
    align: 'left',
    style: {
      fontSize: '20px',
    },
  };

  optionsKD.subtitle = {
    text: 'Kills / Deads',
    align: 'left',
  };

  optionsKD.xaxis = {
    type: 'Kills',
    categories: [1991, 1992, 1993, 1994],
  };

  const optionsWL = { ...apexChartOptions };

  optionsWL.title = {
    text: 'W / L',
    align: 'left',
    style: {
      fontSize: '20px',
    },
  };

  optionsWL.subtitle = {
    text: 'Wins / Looses',
    align: 'left',
  };

  optionsWL.xaxis = {
    type: 'W/L',
    categories: [1991, 1992, 1993, 1994],
  };

  const seriesWeapons = [
    {
      name: 'Weapons used',
      data: [143, 200, 50, 157],
    },
  ];

  const seriesKD = [
    {
      name: 'K/D ratio',
      data: [43, 53, 50, 57],
    },
  ];

  const seriesWL = [
    {
      name: 'W/L ratio',
      data: [200, 15, 50, 130],
    },
  ];

  const optionsWeapons = { ...apexChartOptions };

  optionsWeapons.title = {
    text: 'Weapons',
    align: 'left',
    style: {
      fontSize: '20px',
    },
  };

  optionsWeapons.subtitle = {
    text: 'Most used weapons',
    align: 'left',
  };

  optionsWeapons.xaxis = {
    type: 'W/L',
    categories: ['AWP', 'AK47', 'M4A4', 'M4A1-S'],
  };

  return {
    optionsKD,
    seriesKD,
    optionsWL,
    seriesWL,
    optionsWeapons,
    seriesWeapons,
  };
}
