import { useTranslation } from 'react-i18next';
import bgContact from 'assets/images/img_contact.png';
import useBanner from 'hooks/useBanner';
import Banner from 'components/templates/Banner';
import Container from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import FormContact from 'components/organisms/form/FormContact';

export default function Contact() {
  const { t } = useTranslation('global');
  const { contact } = useBanner();

  return (
    <div className="w-full bg-body-500">
      <Banner data={contact} />
      <Container className="lg:max-w-[1045px]">
        <div className="lg:flex my-12 justify-center">
          <div className="lg:flex flex-col lg:w-[40%] self-center">
            <h4 className="uppercase text-white font-black text-lg | lg:text-xl">
              {t('pages.titles.contact-us')}
            </h4>
            <h5 className="uppercase text-white font-black text-xl mb-6 | xl:text-3xl">
              {t('pages.titles.contact-tagline')}
            </h5>
            <FormContact />
          </div>
          <Image src={bgContact} className="lg:max-w-sm" />
        </div>
      </Container>
    </div>
  );
}
