export const customStyles = {
  menu: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#000000',
  }),
  control: (base, state) => ({
    ...base,
    background: '#000000',
    borderColor: state.isFocused ? '#FBE417' : '#FBE417',
    clipPath: 'polygon(90% 0, 100% 34%, 100% 100%, 10% 100%, 0 66%, 0 0)',
    height: '3rem',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? '#FBE417' : '#FBE417',
    },
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? '#FBE417' : '#FBE417',
    backgroundColor: state.isSelected ? '#000000' : '#3b3b3b',
  }),
  placeholder: (defaultStyles, state) => ({
    ...defaultStyles,
    color: '#FBE417',
    textTransform: 'uppercase',
    paddingLeft: '1rem',
    fontSize: '0.8rem',
    lineHeight: '1.1',
  }),
  input: (defaultStyles) => ({
    ...defaultStyles,
    color: '#FBE417',
    backgroundColor: 'transparent',
  }),
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    color: '#FBE417',
    paddingLeft: '1rem',
    textTransform: 'uppercase',
  }),
};
