import { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import Logo from 'components/atoms/Logo';
import { Link, redirect } from 'react-router-dom';
import Navigation from 'components/molecules/Navigation';
import LoginWithSteamButton from 'components/atoms/LoginWithSteamButton';
import Container from 'components/atoms/Container';
import User from 'components/atoms/User';
import useSession from 'hooks/useSession';
import store from 'app/store';
import { setAlert } from 'features/alert/alertSlice';

function Header() {
  const { isUserLoggedIn, userData } = useSelector((state) => state.user);
  const { sessionExpiration } = useSession();
  const [formattedExpiration, setFormattedExpiration] = useState(null);
  const [logged, setLogged] = useState(null);
  const [logoutHandled, setLogoutHandled] = useState(false); // Estado para controlar si el logout ya ha sido manejado

  function handleLogout() {
    window.location.assign(process.env.REACT_APP_LOGOUT_URL);
    store.dispatch(
      setAlert({ message: 'alert.session-expired', type: 'error' })
    );
  }

  useEffect(() => {
    if (sessionExpiration !== undefined) {
      const date = new Date(Number(sessionExpiration));
      const dateISO8601 = date.toISOString();
      setFormattedExpiration(dateISO8601);
    }
  }, [userData, sessionExpiration]);

  useEffect(() => {
    if (formattedExpiration) {
      const currentDate = new Date();
      const expirationDate = new Date(formattedExpiration);

      if (isUserLoggedIn === true) {
        if (currentDate > expirationDate) {
          // Verifica si el logout aún no ha sido manejado
          localStorage.clear();
          setLogged(false);
          redirect('/');
          handleLogout();
          setLogoutHandled(true); // Marca que el logout ha sido manejado
        } else {
          setLogged(true);
        }
      }
    }
  }, [formattedExpiration, isUserLoggedIn, logoutHandled]);

  useEffect(() => {
    if (logged === false) {
      handleLogout();
    }
  }, [logged]);

  return (
    <header className="bg-header-100 relative z-30 box-shadow">
      <Container className="py-5 | md:flex md:items-center md:justify-between md:gap-6 | lg:py-8">
        <Link to="/" className="w-[110px] lg:w-[200px] xl:w-[259px] block">
          <Logo width="100%" />
        </Link>
        <Navigation />
        {isUserLoggedIn && logged ? (
          <div className="absolute w-9 h-9 top-3 right-4 | md:relative md:top-0 md:right-0 md:w-10 md:h-10 | lg:w-14 lg:h-14 -lg:my-6">
            <User data={userData} logout />
          </div>
        ) : (
          <LoginWithSteamButton />
        )}
      </Container>
      <div className="lg:decoration bg-header-100 absolute left-0 lg:w-60 lg:h-5 box-shadow"></div>
      <div className="lg:decoration bg-header-100 absolute right-0 lg:w-60 lg:h-5 box-shadow"></div>
    </header>
  );
}

export default memo(Header);
