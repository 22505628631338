import { useTranslation } from 'react-i18next';

import { acceptFriendRequest, rejectFriendRequest } from 'app/socketConnection';

const FriendRequest = (props) => {
  const { t } = useTranslation('global');

  const acceptFriendRequestHelper = () => {
    acceptFriendRequest(props.request.id);
  };

  const deleteFriendRequestHelper = () => {
    rejectFriendRequest(props.request.id);
  };

  return (
    <>
      {props.index % 2 === 0 ? (
        <div className="bg-[#989898] flex flex-row overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-500 items-center gap-px">
          <div className="bg-black flex items-center justify-center w-[40px] h-[40px] flex-shrink-0 relative">
            <div className="relative w-[32px] h-[32px] rounded-full overflow-hidden">
              <img
                src={props.request.user.userAvatar}
                alt="friendAvatar"
                className="h-full w-full object-cover absolute"
              />
            </div>
          </div>
          <div
            className="flex flex-row flex-shrink-0 space-around items-center pl-2"
            style={{ width: 'calc(100% - 11rem)' }}
          >
            <p
              className="leading-tight uppercase font-bold text-white break-all"
              style={{ fontSize: '10px' }}
            >
              {props.request.user.userName}
            </p>
          </div>
          <div className="flex justify-around space-x-2 ml-auto pr-2">
            <button
              className="clip-path-button py-1 px-2 bg-black border-2 border-white relative"
              onClick={deleteFriendRequestHelper}
            >
              <p className="text-white text-[9px] uppercase font-bold">
                {t('components.friend-request.decline-button.text')}
              </p>
            </button>
            <button
              className="clip-path-button py-1 px-2 bg-black border-2 border-yellow-500 relative"
              onClick={acceptFriendRequestHelper}
            >
              <p className="text-yellow-500 text-[11px] uppercase font-bold">
                {t('components.friend-request.accept-button.text')}
              </p>
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-[#989898] flex flex-row overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-500 items-center gap-px">
          <div className="bg-black flex items-center justify-center w-[40px] h-[40px] flex-shrink-0 relative">
            <div className="relative w-[32px] h-[32px] rounded-full overflow-hidden">
              <img
                src={props.request.user.userAvatar}
                alt="friendAvatar"
                className="h-full w-full object-cover absolute"
              />
            </div>
          </div>
          <div
            className="flex flex-row flex-shrink-0 space-around items-center pl-2"
            style={{ width: 'calc(100% - 11rem)' }}
          >
            <p
              className="leading-tight uppercase font-bold text-white break-all"
              style={{ fontSize: '10px' }}
            >
              {props.request.user.userName}
            </p>
          </div>
          <div className="flex justify-around space-x-2 ml-auto pr-2">
            <button
              className="clip-path-button py-1 px-2 bg-black border-2 border-white relative"
              onClick={deleteFriendRequestHelper}
            >
              <p className="text-white text-[9px] uppercase font-bold">
                {t('components.friend-request.decline-button.text')}
              </p>
            </button>
            <button
              className="clip-path-button py-1 px-2 bg-black border-2 border-yellow-500 relative"
              onClick={acceptFriendRequestHelper}
            >
              <p className="text-yellow-500 text-[11px] uppercase font-bold">
                {t('components.friend-request.accept-button.text')}
              </p>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FriendRequest;
