import { NavLink } from 'react-router-dom';

export default function FooterNavigation(props) {
  const { data, type } = props;

  return (
    <ul>
      {data
        ?.filter((page) => page.type === type)
        ?.map((page) => (
          <li key={page.id}>
            {!page.external ? (
              <NavLink
                to={page.link}
                className="text-white block md:inline-block text-base mt-3 mb-3 md:mt-0 md:mb-5 ease-linear hover:text-yellow-500"
              >
                {page.name}
              </NavLink>
            ) : (
              <a
                href={page.link}
                target="_blank"
                rel="noreferrer"
                className="text-white block md:inline-block text-base mt-3 mb-3 md:mt-0 md:mb-5 ease-linear hover:text-yellow-500"
              >
                <span className="flex items-center gap-3 ease-linear text-current">
                  {page.icon}
                  <span>{page.name}</span>
                </span>
              </a>
            )}
          </li>
        ))}
    </ul>
  );
}
