export default function Logo(props) {
  const { width, color } = props;
  return (
    <svg
      width={width || '257px'}
      height="100%"
      viewBox="0 0 257 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.0121 10.5679L31.5681 7.38791L30.6743 9.25443L23.5919 24.3249L13.5527 3.10182H0.694336L22.698 49.6957L37.0003 19.2783L33.0121 10.5679ZM48.4834 3.03269L39.2694 22.4583L38.6505 23.841L32.8746 36.0079L32.9434 36.1462L38.3755 47.5527L39.3382 49.6266L45.6642 36.2844L61.4104 3.03269H48.4834ZM54.5483 49.6957H68.2318L81.5714 27.6431L74.7641 16.3057L54.5483 49.6957ZM83.7718 1.37356L76.9644 12.6418L96.4238 45.064L99.243 49.7649H112.926L83.7718 1.37356ZM126.324 18.8636C126.668 18.3797 127.08 17.8957 127.493 17.481C129.762 15.1997 132.719 13.9553 135.882 13.9553C139.045 13.9553 142.002 15.1997 144.271 17.481C145.371 18.587 146.265 19.9005 146.884 21.3523L147.159 21.9053H159.261L158.986 20.7301C157.679 14.854 154.241 9.73835 149.29 6.35096C145.371 3.58574 140.764 2.134 135.951 2.134C129.006 2.134 122.405 5.17574 117.935 10.4988L117.385 11.1901L125.774 19.624L126.324 18.8636ZM138.495 29.7862L142.758 35.7314C140.764 37.114 138.426 37.8744 135.951 37.8744C132.788 37.8744 129.831 36.6301 127.562 34.3488C125.293 32.0675 124.055 29.0949 124.055 25.9149C124.055 25.2927 124.124 24.6705 124.193 24.0483L124.261 23.5644L115.185 14.4392L114.635 15.6836C113.122 18.8636 112.366 22.3201 112.366 25.9149C112.366 32.2749 114.841 38.2201 119.31 42.7136C123.78 47.207 129.693 49.6957 136.019 49.6957C140.214 49.6957 144.34 48.5897 147.915 46.4466V49.6266H159.604V29.9244L138.495 29.7862ZM178.109 37.9436V34.6253L166.419 22.8731V49.7649H205.819V38.0127L178.109 37.9436ZM205.819 31.9292V20.177H185.466L179.346 14.0244H205.819V2.27226H166.35V17.6192L180.653 31.9983L205.819 31.9292ZM212.752 49.6957H224.442V34.1414L212.752 22.3892V49.6957ZM244.864 12.3653L244.589 12.6418V32.4131L214.884 2.54878L214.609 2.27226H212.752V17.0662L224.442 28.8183L231.937 36.3536L244.796 49.281L245.277 49.7649H256.279V0.889648L244.864 12.3653Z"
        fill={color || '#FBE417'}
      />
    </svg>
  );
}
