import { useSelector } from 'react-redux';
import wagenCoin from 'assets/images/wagencoin.svg';
import CardBgVs from '../atoms/CardBgVs';
import Image from 'components/atoms/Image';
import { closeGame } from 'features/user/userSlice';
import store from 'app/store';
import { sendMessage } from 'app/socketConnection';

export default function FinishedMatch() {
  const { userData } = useSelector((state) => state.user);

  const handleCloseEndGame = () => {
    store.dispatch(closeGame());
    sendMessage({ action: 'userData' });
  };

  if (
    userData?.currentMatch?.currentlyPlaying === false &&
    userData?.finishedMatch?.finishedPlaying === true
  )
    return (
      <div
        data-testid="finishedMatch"
        className="bg__glass bg-black bg-opacity-80 flex justify-center items-center fixed w-screen h-screen right-0 top-0 z-50"
      >
        <div className="bg-body-200 max-w-[829px] w-11/12 rounded-lg flex flex-col z-10 py-8 px-8 relative">
          <div className="flex flex-col flex-grow md:flex-row justify-between items-center w-full">
            <div className="flex flex-col justity-center items-center w-full max-w-[330px] relative">
              {userData?.finishedMatch?.match?.oponent1 && (
                <article className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center flex-col w-3/4 mx-auto z-20">
                  <div className="relative overflow-hidden border-4 border-yellow-500 rounded-full w-20 h-20 md:w-36 md:h-36 flex items-center justify-center mx-auto mt-3">
                    <Image
                      data-testid="oponent1-avatar"
                      src={userData?.finishedMatch?.match?.oponent1?.avatar}
                      alt="avatar"
                      type="cover"
                    />
                  </div>
                  <div className="mt-4 mb-0 py px-5 uppercase font-bold text-center">
                    <p
                      data-testid="oponent1-name"
                      className="text-gray-100 mb-0 text-[18px] break-words"
                    >
                      {userData?.finishedMatch?.match?.oponent1?.name}
                    </p>
                  </div>
                  <div className="text-gray-300 block text-center">
                    <p className="flex justify-center gap-1">
                      <span className="block text-gray-300 font-light">
                        Unique Code:
                      </span>
                      <span className="font-bold">
                        {userData?.finishedMatch?.match?.oponent1?.idPublic}
                      </span>
                    </p>
                    <div className="flex justify-center mt-4 gap-3">
                      <div
                        data-testid="oponent1-kd"
                        className={`clip-path-button text-black py-1 px-3 font-bold ${
                          userData?.finishedMatch?.match?.senderWins === true
                            ? 'bg-yellow-500'
                            : 'bg-[#EB433A]'
                        } uppercase font-bold text-lg`}
                      >
                        <span>K/D: </span>
                        <span className="font-light">
                          {parseFloat(
                            userData?.finishedMatch?.match?.oponent1?.kd
                          ).toFixed(2) ?? parseFloat(0).toFixed(2)}
                        </span>
                      </div>
                      <div
                        data-testid="oponent1-win"
                        className={`clip-path-button text-black py-1 px-3 font-bold ${
                          userData?.finishedMatch?.match?.senderWins === true
                            ? 'bg-yellow-500'
                            : 'bg-[#EB433A]'
                        } uppercase font-bold text-lg`}
                      >
                        <span>Win: </span>
                        <span className="font-light">
                          {`${
                            parseFloat(
                              userData?.finishedMatch?.match?.oponent1?.win
                            ).toFixed(2) ?? parseFloat(0).toFixed(2)
                          }%`}
                        </span>
                      </div>
                    </div>
                  </div>
                </article>
              )}
              <CardBgVs />
              {userData?.finishedMatch?.match?.oponent1 && (
                <div className="absolute top-0 right-0 flex items-center justify-center w-28 h-12 py-4 px-4 z-30">
                  <div className="flex gap-1 items-center">
                    <span
                      data-testid="oponent1-waggies"
                      className={`uppercase font-bold text-lg flex items-center text-nowrap ${
                        userData?.finishedMatch?.match?.senderWins === true
                          ? 'text-[#50C878]'
                          : 'text-[#EB433A]'
                      }`}
                    >
                      {`${
                        userData?.finishedMatch?.match?.senderWins === true
                          ? '+'
                          : '-'
                      } ${userData?.finishedMatch?.match?.wagiesBet}`}
                    </span>
                    <Image src={wagenCoin} width="25px" />
                  </div>
                </div>
              )}
            </div>
            <div className="w-[100px] flex justify-center items-center z-20 relative">
              <div className="my-2 md:my-7 w-12 h-12 md:w-24 md:h-24 flex items-center justify-center flex-col">
                <p className="versus">VS</p>
              </div>
              <div className="absolute -bottom-4 bg-yellow-500 rounded-md text-black min-w-[60px] text-center px-4 text-xl flex items-center">
                <strong className="font-bold mr-1">
                  {`${userData?.finishedMatch?.match?.oponent1?.killsInMatch}`}
                </strong>
                <span>-</span>
                <strong className="font-bold ml-1">
                  {`${userData?.finishedMatch?.match?.oponent2?.killsInMatch}`}
                </strong>
              </div>
            </div>
            <div className="flex flex-col justity-center items-center w-full max-w-[330px] relative">
              {userData?.finishedMatch?.match?.oponent2 && (
                <article className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center flex-col w-3/4 mx-auto z-20">
                  <div className="relative overflow-hidden border-4 border-yellow-500 rounded-full w-20 h-20 md:w-36 md:h-36 flex items-center justify-center mx-auto mt-3">
                    <Image
                      data-testid="oponent2-avatar"
                      src={userData?.finishedMatch?.match?.oponent2?.avatar}
                      alt="avatar"
                      type="cover"
                    />
                  </div>
                  <div className="mt-4 mb-0 py px-5 uppercase font-bold text-center">
                    <p
                      data-testid="oponent2-name"
                      className="text-gray-100 mb-0 text-[18px] break-words"
                    >
                      {userData?.finishedMatch?.match?.oponent2?.name}
                    </p>
                  </div>
                  <div className="text-gray-300 block text-center">
                    <p className="flex justify-center gap-1">
                      <span className="block text-gray-300 font-light">
                        Unique Code:
                      </span>
                      <span className="font-bold">
                        {userData?.finishedMatch?.match?.oponent2?.idPublic}
                      </span>
                    </p>
                    <div className="flex justify-center mt-4 gap-3">
                      <div
                        data-testid="oponent2-kd"
                        className={`clip-path-button text-black py-1 px-3 font-bold ${
                          userData?.finishedMatch?.match?.senderWins === false
                            ? 'bg-yellow-500'
                            : 'bg-[#EB433A]'
                        } uppercase font-bold text-lg`}
                      >
                        <span>K/D: </span>
                        <span className="font-light">
                          {`${parseFloat(
                            userData?.finishedMatch?.match?.oponent2?.kd
                          ).toFixed(2)}`}
                        </span>
                      </div>
                      <div
                        data-testid="oponent2-win"
                        className={`clip-path-button text-black py-1 px-3 font-bold ${
                          userData?.finishedMatch?.match?.senderWins === false
                            ? 'bg-yellow-500'
                            : 'bg-[#EB433A]'
                        } uppercase font-bold text-lg`}
                      >
                        <span>Win: </span>
                        <span className="font-light">
                          {`${parseFloat(
                            userData?.finishedMatch?.match?.oponent2?.win
                          ).toFixed(2)}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                </article>
              )}
              <CardBgVs />
              {userData?.finishedMatch?.match?.oponent2 && (
                <div className="absolute top-0 right-0 flex items-center justify-center w-28 h-12 py-4 px-4 z-30">
                  <div className="flex gap-1 items-center">
                    <span
                      data-testid="oponent2-waggies"
                      className={`uppercase font-bold text-lg ${
                        userData?.finishedMatch?.match?.senderWins === false
                          ? 'text-[#50C878]'
                          : 'text-[#EB433A]'
                      } uppercase font-bold text-lg`}
                    >
                      {`${
                        userData?.finishedMatch?.match?.senderWins === false
                          ? `+ ${userData?.finishedMatch?.match?.wagiesBet}`
                          : `- ${userData?.finishedMatch?.match?.wagiesBet}`
                      }`}
                    </span>
                    <Image src={wagenCoin} width="25px" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <button
            className="absolute -top-4 -right-2 w-8 h-8 bg-red-500 rounded-md"
            onClick={handleCloseEndGame}
          >
            <div className="font-bold text-white text-lg">x</div>
          </button>
        </div>
      </div>
    );
  else return <div></div>;
}
