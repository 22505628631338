import useHome from 'hooks/useHome';
import useSection from 'hooks/useSection';
import Container from 'components/atoms/Container';
import Card from 'components/molecules/Card';
import FeaturedWeapons from 'components/organisms/landing/FeaturedWeapons';
import Banner from 'components/templates/Banner';
import FriendList from 'components/organisms/FriendList';
import LastGamesList from 'components/organisms/LastGamesList';
import FeaturedVideos from 'components/organisms/landing/FeaturedVideos';

export default function Home() {
  const { headers, promotions, suscription } = useHome();
  const { featuredWeapons } = useSection();

  const { featuredVideos } = useSection();

  return (
    <div className="w-full h-full bg-body-500 pb-12 pt-8 | lg:pt-20">
      <Container>
        <section className="flex flex-col gap-8 pb-12 w-full | lg:flex-row lg:gap-24 lg:pb-16">
          {headers?.map((item) => (
            <Card key={item.id} variant="basic" data={item} />
          ))}
        </section>

        <section className="flex flex-col gap-8 mb-12 | lg:grid lg:grid-cols-2 lg:gap-24 lg:gap-y-12">
          <FriendList />
          <LastGamesList />
        </section>

        <section className="w-full mb-20 lg:grid lg:grid-cols-2 lg:gap-16">
          {promotions?.map((promotion) => (
            <Card variant="medium" key={promotion.id} data={promotion} />
          ))}
        </section>

        <FeaturedWeapons data={featuredWeapons} />

        <Banner variant="promotional" data={suscription} />

        <FeaturedVideos data={featuredVideos} />
      </Container>
    </div>
  );
}
