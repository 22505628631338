import { useTranslation } from 'react-i18next';
import Instagram from 'components/atoms/icons/Instagram';
import Twitter from 'components/atoms/icons/Twitter';
import Mail from 'components/atoms/icons/Mail';

const useNavigation = () => {
  const { t } = useTranslation('global');

  const navigation = [
    {
      id: 0,
      name: 'Home',
      link: '/user/home',
    },
    {
      id: 1,
      name: `${t('components.navBar.pages.play')}`,
      link: '/user/play',
    },
    {
      id: 2,
      name: `${t('components.navBar.pages.friends')}`,
      link: '/user/friends',
    },
    {
      id: 3,
      name: `${t('components.navBar.pages.store')}`,
      link: '/store',
    },
    {
      id: 4,
      name: `${t('components.navBar.pages.contact')}`,
      link: '/contact',
    },
  ];

  const navigationFooter = [
    {
      id: 0,
      name: `${t('components.navBar.pages.about')}`,
      link: '/about-us',
      type: 'page',
    },
    {
      id: 1,
      name: `${t('components.navBar.pages.play')}`,
      link: '/play',
      type: 'page',
    },
    {
      id: 2,
      name: `${t('components.navBar.pages.store')}`,
      link: '/store',
      type: 'page',
    },
    {
      id: 3,
      name: `${t('components.navBar.pages.contact')}`,
      link: '/contact',
      type: 'page',
    },
    {
      id: 4,
      name: `${t('components.navBar.pages.questions')}`,
      link: '/questions',
      type: 'extra',
    },
    {
      id: 5,
      name: `${t('components.navBar.pages.rules')}`,
      link: '/rules',
      type: 'extra',
    },
    {
      id: 7,
      name: 'Medium',
      link: '/medium',
      type: 'extra',
      external: true,
    },
    {
      id: 8,
      name: `${t('components.navBar.pages.privacy')}`,
      link: '/privacy-policy',
      type: 'legal',
    },
    {
      id: 9,
      name: `${t('components.navBar.pages.cookies')}`,
      link: '/cookies-policy',
      type: 'legal',
    },
    {
      id: 10,
      name: `${t('components.navBar.pages.terms')}`,
      link: '/terms',
      type: 'legal',
    },
    {
      id: 11,
      name: 'Instagram',
      link: '',
      icon: <Instagram />,
      external: true,
      type: 'rrss',
    },
    {
      id: 12,
      name: 'Twitter',
      link: '',
      icon: <Twitter />,
      external: true,
      type: 'rrss',
    },
    {
      id: 13,
      name: 'E-mail',
      link: '',
      icon: <Mail />,
      external: true,
      type: 'rrss',
    },
  ];

  return { navigation, navigationFooter };
};

export default useNavigation;
