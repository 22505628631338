import Container from 'components/atoms/Container';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';

export default function BannerMiddle(props) {
  const { data, orientation } = props;

  return (
    <div className="w-full">
      <Container className="w-full md:max-w-full flex flex-col | md:h-[350px] | xl:h-[490px] md:flex-row overflow-hidden">
        <article
          className={`bg-body-100 order-3 md:order-1 md:w-[45%] pl-[5%] pr-[5%] flex items-center py-8 relative z-10 ${
            orientation === 'right' ? 'justify-end' : ''
          }`}
        >
          <div className="text-white rounded-[10px] bg-body-200 py-7 px-8 relative | max-w-[415px]">
            <h1 className="uppercase text-xl font-[900] | md:text-4xl mb-4">
              {data.title}
            </h1>
            <p className="text-lg leading-5">{data.paragraph}</p>
            <div className="mt-10 flex items-center justify-between md:justify-start | md:gap-5 | lg:gap-8">
              <Button
                to={data.cta.url}
                variant={data.cta.variant}
                type={data.cta.type}
              >
                {data.cta.name}
              </Button>
            </div>
            <Image
              src={data.imageSecondary.src}
              className="absolute w-[160px] -bottom-8 -right-4 | md:w-[220px] | xl:-bottom-32 xl:-right-16 xl:w-[280px]"
            />
          </div>
          <div
            className={`bg-gradient-to-r from-body-100 to-90% hidden md:block h-full w-[26%] absolute top-0 ${
              orientation === 'right' ? '-left-1/4 rotate-180' : '-right-1/4'
            }`}
          ></div>
        </article>
        <div
          className={`h-[200px] relative order-2 overflow-hidden | md:w-[55%] md:h-[100%] ${
            orientation === 'right' ? 'md:-order-1' : ''
          }`}
        >
          <Image
            src={data.imagePrimary.src}
            alt={data.imagePrimary.alt}
            type="cover"
          />
        </div>
      </Container>
    </div>
  );
}
