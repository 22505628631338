import Container from 'components/atoms/Container';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Card from 'components/molecules/Card';

export default function FeaturedWeapons(props) {
  const { data } = props;

  return (
    <div className="card-store__list border-b-2 border-gray-900 py-16 w-full">
      <Container>
        <div className="text-center mb-16 | xl:flex xl:justify-center xl:items-center xl:gap-8">
          <h3 className="text-white uppercase font-black mb-4 text-3xl | xl:mb-0 xl:text-6xl">
            {data.title}
          </h3>
          <Button
            variant="link"
            to={data.cta.link}
            className="bg-body-200 text-white"
          >
            {data.cta.name}
          </Button>
        </div>
        <Grid className="grid-cols-1 gap-12 | md:grid-cols-2 md:gap-16 | xl:grid-cols-3 xl:gap-24">
          {data?.nodes?.slice(0, 3)?.map((item) => (
            <Card key={`${item.id}-${item.name}`} data={item} />
          ))}
        </Grid>
      </Container>
    </div>
  );
}
