import { createSlice } from '@reduxjs/toolkit';

export const featuredVideosSlice = createSlice({
  name: 'featuredVideos',
  initialState: require('data/example/videos.json'),
  reducers: {
    updateFeaturedVideos: (state, action) => {
      // Get the featured videos from the API server
      state.featureVideos.tradelink = action.payload;
    },
  },
});

export const { updateFeaturedVideos } = featuredVideosSlice.actions;

export default featuredVideosSlice.reducer;
