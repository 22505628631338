import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';

export default function CardSimple(props) {
  const { data } = props;

  return (
    <div className="text-white rounded-[10px] bg-body-200 py-7 px-8 relative w-full overflow-hidden | lg:px-10 order-1">
      <div className="h-20 flex items-center justify-start | lg:h-36 | xl:h-56">
        <Button
          to={data.cta.url}
          variant={data.cta.variant}
          type={data.cta.type}
          className="relative z-10"
        >
          {data.cta.name}
        </Button>
        <Image type="cover" src={data.image.src} alt={data.image.alt} />
      </div>
    </div>
  );
}
