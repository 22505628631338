import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';

import steamIcon from 'assets/images/icons/SteamIcon.svg';

export default function LoginWithSteamButton() {
  const { t } = useTranslation('global');

  const [showPupUp, setShowPopUp] = useState(false);
  const [checkedTermsAndConditions, setCheckedTermsAndConditions] =
    useState(false);
  const [acceptedTemsAndConditions, setAcceptedTemsAndConditions] =
    useState(false);

  const alert = useAlert();

  // check if previously the user has accepted the terms and conditions
  useEffect(() => {
    // get the terms and conditions acceptance from local storage
    const localStorageWagen = JSON.parse(
      localStorage.getItem('acceptedWagenGamesTermsAndConditions')
    );

    // check if they have been accepted
    if (localStorageWagen) {
      setAcceptedTemsAndConditions(true);
    }
  }, []);

  // functions that shows or hide the rectangle with terms and conditions and go to steam button
  const showTermsPopUp = () => {
    setShowPopUp(!showPupUp);
  };

  // redirects the user to the steam loggin page
  const redirectToLogin = () => {
    if (checkedTermsAndConditions || acceptedTemsAndConditions) {
      localStorage.setItem('acceptedWagenGamesTermsAndConditions', 'true');
      window.location = process.env.REACT_APP_STEAM_LOGIN_URL;
    } else {
      alert.error('Accept the terms and conditions');
    }
  };

  // function to handle when the user checks the terms and conditios button
  const handleCheckBoxChange = () => {
    setCheckedTermsAndConditions(!checkedTermsAndConditions);
  };

  return (
    <div>
      <button
        onClick={showTermsPopUp}
        className="bg-black bg-opacity-30 border px-4 py-2 rounded-lg flex items-center space-x-2 justify-center absolute top-3 right-3 hover:bg-opacity-50 hover:duration-500 | md:relative md:top-0 md:right-0 md:w-40 | xl:w-auto"
      >
        <img src={steamIcon} className="w-5 lg:w-6 xl:w-8" alt="Steam" />
        <span className="text-white hidden md:block leading-4">
          {t('components.loggin-with-steam.loggin-button.text')}
        </span>
      </button>

      {showPupUp ? (
        <div
          className="fixed w-screen h-screen right-0 top-0 z-10 bg-black bg-opacity-20 flex flex-col items-center justify-center"
          onClick={showTermsPopUp}
        >
          <div
            className="w-[80%] h-[50%] md:w-[40%] md:h-[40%] bg-black bg-opacity-80 md:bg-opacity-90 flex flex-col items-center justify-center rounded-lg border-double border-4 border-gray-500"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* go to steam button */}
            <button
              onClick={redirectToLogin}
              className="border-2 px-4 py-2 rounded-lg flex items-center space-x-2 justify-center bg-brown-400 bg-opacity-50 hover:bg-opacity-30 hover:duration-200 duration-700"
            >
              <img src={steamIcon} alt="InstagramIcon" />
              <p className="text-white block">
                {t('components.loggin-with-steam.loggin-button.text')}
              </p>
            </button>
            {/* terms and conditions */}
            {acceptedTemsAndConditions ? (
              <div></div>
            ) : (
              <div className="flex my-5 items-center">
                <label htmlFor="terms">
                  <input
                    id="terms"
                    type={'checkbox'}
                    checked={checkedTermsAndConditions}
                    onChange={handleCheckBoxChange}
                    className={' mx-3'}
                  />
                  <p className="text-gray-400 inline-flex gap-1">
                    <span>
                      {t(
                        'components.loggin-with-steam.terms-conditions.accept'
                      )}
                    </span>
                    <b>
                      <u>
                        <a href="/terms" target="_blank">
                          {t(
                            'components.loggin-with-steam.terms-conditions.message'
                          )}
                        </a>
                      </u>
                    </b>
                  </p>
                </label>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
