import { useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connectSocket, disconnectSocket } from 'app/socketConnection';
import { Loading } from 'pages';
import Games from 'components/pages/Games';
import Friends from 'components/pages/Friends';
import Profile from 'components/pages/Profile';
import Home from 'components/pages/Home';

function ProtectedRoute(props) {
  const { subscreen } = props;
  const { t } = useTranslation('global');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const socketLoginTried = useRef(false);
  const { isUserLoggedIn, isSocketConnected, userData } = useSelector(
    (state) => state.user
  );

  const alert = useAlert();

  useEffect(() => {
    if (pathname === '/user' || pathname === '/user/') {
      navigate('/user/home');
    }
  }, [navigate, pathname]);

  // effect for connecting the socket when the user is authenticated
  useEffect(() => {
    if (!socketLoginTried.current) {
      socketLoginTried.current = true;
      if (isUserLoggedIn && !isSocketConnected) connectSocket();
      else if (!isUserLoggedIn && isSocketConnected) disconnectSocket();
    }
    // eslint-disable-next-line
  }, []);

  if (isUserLoggedIn && isSocketConnected && userData) {
    if (subscreen === 'home') {
      return <Home />;
    }

    if (subscreen === 'friends') {
      return <Friends />;
    }

    if (subscreen === 'profile') {
      return <Profile />;
    }

    if (subscreen === 'play') {
      return <Games />;
    }
  } else if (isUserLoggedIn) {
    return <Loading />;
  } else {
    alert.error(`${t('alert.you-are-not-logged-in')}`);
    setTimeout(() => {
      navigate('/');
    }, 1000);
    return <Loading />;
  }
}
export default ProtectedRoute;
