import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/atoms/Button';
import User from 'components/atoms/User';
import wagenCoin from 'assets/images/wagencoin.svg';
import Image from 'components/atoms/Image';

export default function CurrentUser() {
  const { t } = useTranslation('global');
  const { userData } = useSelector((state) => state.user);

  return (
    <div
      id="currentUser"
      className="bg-[#4b4b4b] rounded-xl relative h-[433px] w-full overflow-auto mb-4"
    >
      <div className="relative z-20 flex flex-col h-100">
        <div className="relative top-[0!important] right-[0!important] mt-20 mx-auto w-32 h-32 mb-4 | md:w-32 md:h-32 md:ml-auto md:my-16 md:mb-4 | lg:w-32 lg:h-32 ">
          <User data={userData} />
        </div>
        <p className="text-white text-center font-bold text-[22px]">
          {userData.name}
        </p>
        <p className="text-white text-center font-light text-[18px] mb-5">
          {`Unique code: ${userData.idPublic}`}
        </p>
        <div className="flex justify-between rounded-md w-8/12 bg-black mx-auto py-3 px-3 mb-5">
          <span className="text-white block">{`Win: ${parseFloat(
            userData.win
          ).toFixed(2)}`}</span>
          <span className="text-white block">{`K/D: ${parseFloat(
            userData.kd
          ).toFixed(2)}`}</span>
        </div>
        <div className="flex items-center justify-center">
          <Button variant="link" to="/buyWaggies" type="primary">
            {`${t('pages.store.buy-wagies-button.text')}`}
          </Button>
        </div>
      </div>
      <div className="absolute top-0 right-0 flex items-center justify-end py-4 px-4 z-30 w-28 h-[60px] mr-5">
        <div className="flex gap-1 items-center">
          <span className="text-white uppercase font-bold text-lg">
            {userData?.waggies}
          </span>
          <Image src={wagenCoin} width="25px" />
        </div>
      </div>
    </div>
  );
}
